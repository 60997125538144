import { lazy } from 'react'

// project import
import Loadable from 'components/Loadable'
import { MODULE } from 'constants/list-module'

const DepotPage = Loadable(lazy(() => import('pages/Depot/index')))

const DepotRoutes = [
  {
    path: 'depot',
    element: <DepotPage />,
    code: MODULE.DEPOT,
  },
]

export default DepotRoutes
