import { lazy } from 'react'

// project import
import Loadable from 'components/Loadable'
import { MODULE } from 'constants/list-module'
const RoleManagement = Loadable(lazy(() => import('pages/RoleManagement/Listing/index')))
const RoleDetail = Loadable(lazy(() => import('pages/RoleManagement/Detail/index')))
const ListUser = Loadable(lazy(() => import('pages/RoleManagement/Listing/ListUser')))

const RoleManagementRoutes = [
  {
    path: 'role-management',
    element: <RoleManagement />,
    code: MODULE.PERMISSION,
  },
  {
    path: 'role-management/:id',
    element: <RoleDetail />,
    code: MODULE.PERMISSION,
  },
  {
    path: 'role-management/:id/users',
    element: <ListUser />,
    code: MODULE.PERMISSION,
  },
]

export default RoleManagementRoutes
