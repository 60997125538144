import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";

// export interface Pagination<T> {
//   pagination: {
//     "total": number,
//     "per_page": number,
//     "current_page": number,
//     "last_page": number,
//     "from": number,
//     "to": number
//   }
//   data: T[]
// }

const initialState = {
  loading: false,
  error: false,
  data: {
    data: [],
    pagination: {
      total: 0,
      per_page: 0,
      current_page: 0,
      last_page: 0,
      from: 0,
      to: 0
    }
  }
};

const mediasSlice = createSlice({
  initialState,
  name: "medias",
  reducers: {
    getMedias: (state, action) => {
      return {
        ...state,
        loading: true,
        error: false
      };
    },
    getMediasSuccess: (state, action) => {
      return {
        ...state,
        loading: false,
        error: false,
        data: action.payload
      };
    },
    getMediasError: (state, action) => {
      return {
        ...state,
        loading: false,
        error: false
      };
    },
  },
});

export const { actions: mediasActions, reducer: mediasReducer } = mediasSlice;
export const mediasSelector = (state: RootState) => state.medias;
