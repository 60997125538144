import { lazy } from 'react'

// project import
import Loadable from 'components/Loadable'
import { MODULE } from 'constants/list-module'

const PopupEditor = Loadable(lazy(() => import('pages/HomeManage/Popup/Editor')))
const PopupListing = Loadable(lazy(() => import('pages/HomeManage/Popup/List')))

const PopUpRoutes = [
  {
    path: 'popups/popup_editor/:id',
    element: <PopupEditor />,
    code: MODULE.POPUP,
  },
  {
    path: 'popups/popup_editor',
    element: <PopupEditor />,
    code: MODULE.POPUP,
  },
  {
    path: '/popups',
    element: <PopupListing />,
    code: MODULE.POPUP,
  },
]

export default PopUpRoutes
