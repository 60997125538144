import React, { ReactNode } from 'react'
import { Box, styled } from '@mui/material'
import { footerToolbarHeight } from 'config'
import { CSSProperties } from '@mui/styled-engine'

interface IProps {
  className?: string
  children?: any
  header?: JSX.Element | null
  footerToolbar?: JSX.Element | null
  style?: CSSProperties
  isLoading?: boolean
  width?: string | number
  headerActions?: ReactNode[]
}

const MainContent = styled(
  ({ className, width, children, header, footerToolbar, isLoading = false, style = {}, headerActions }: IProps) => {
    return (
      <Box
        className={['main-content', className].join(' ')}
        sx={{
          position: 'relative',
          pt: { xs: 2, sm: 3 },
          pb: { xs: 1, sm: 1 },
          maxWidth: width ? width : 'auto',
          margin: 'auto',
        }}
      >
        <Box
          sx={{
            px: { xs: 2, sm: 3 },
            paddingBottom: `${footerToolbarHeight}px`,
          }}
        >
          {/* {isLoading ? (
            <Box
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
              }}
            >
              <CircularProgress size={30} />
            </Box>
          ) : ( */}
          {children}
          {/* )} */}
        </Box>
        {footerToolbar && <Box className="footer-toolbar">{footerToolbar}</Box>}
      </Box>
    )
  }
)<IProps>(({ theme }) => ({
  '& .footer-toolbar': {
    display: 'inline-flex',
    background: '#fff',
    position: 'fixed',
    bottom: 0,
    right: 0,
    justifyContent: 'flex-end',
    width: '100%',
    borderTop: '1px solid #ddd',
    padding: '5px 24px',
    boxShadow: 'rgb(100 116 139 / 12%) 1px 0px 4px',
  },
}))

export default MainContent
