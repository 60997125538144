import React from 'react'
import PropTypes from 'prop-types'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography } from '@mui/material'
import { isString } from 'lodash'

MessageBox.propTypes = {
  title: PropTypes.string,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  showIcon: PropTypes.bool,
  style: PropTypes.object,
  maxWidth: PropTypes.string,
  minWidth: PropTypes.oneOfType(PropTypes.string, PropTypes.number),
}

MessageBox.defaultProps = {
  title: '',
  onOpen: () => {},
  onClose: () => {},
  showIcon: true,
  style: {},
  maxWidth: 'md',
  minWidth: 700,
}

function MessageBox({ title, onClose, showIcon, onOpen, children, style, maxWidth, minWidth }) {
  return (
    <Dialog open={true} onClose={() => onClose()} maxWidth={maxWidth} style={style}>
      {title && (
        <DialogTitle sx={{ py: 1 }}>
          <Typography sx={{ fontSize: '1rem', fontWeight: 'bold' }}>{title}</Typography>
        </DialogTitle>
      )}
      <DialogContent
        dividers
        sx={{
          minWidth: 700,
        }}
        widh
      >
        <Stack>
          {isString(children) ? (
            <span
              dangerouslySetInnerHTML={{
                __html: children,
              }}
            ></span>
          ) : (
            children
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button size="small" variant="contained" color="error" onClick={() => onClose()}>
          Đóng
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default MessageBox
