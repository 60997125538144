// assets
import SettingsIcon from '@mui/icons-material/Settings'

// icons
import { MODULE } from 'constants/list-module'

const Setting = {
  id: 'setting',
  url: '/settings',
  icon: <SettingsIcon fontSize="small" />,
  title: 'Cài đặt',
  role: ['*'],
  code: MODULE.CONFIGS,
  type: 'group',
}

export default Setting
