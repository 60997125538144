import { useEffect, useState } from 'react'
import { Outlet } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

// material-ui
import { useTheme } from '@mui/material/styles'
import { Box, Toolbar, useMediaQuery } from '@mui/material'

// project import
import Drawer from './Drawer'
import Header from './Header'
// import navigation from 'menu-items'
// import Breadcrumbs from 'components/@extended/Breadcrumbs'
import { drawerWidth, headerHeight, mainBgColor } from '../../config'

// types
import { openDrawer } from 'redux/slices/menuSlice'
import { RootState } from 'redux/store'

// ==============================|| MAIN LAYOUT ||============================== //

interface IProps {
  WrapElement?: Element
}

const MainLayout: React.FC<IProps> = ({ WrapElement }) => {
  const theme = useTheme()
  const matchDownLG = useMediaQuery(theme.breakpoints.down('xl'))
  const dispatch = useDispatch()
  const { drawerOpen } = useSelector((state: RootState) => state.menu)

  // const EnhancedComponent = higherOrderComponent(WrapElement);

  // drawer toggler
  const [open, setOpen] = useState(drawerOpen)
  const handleDrawerToggle = () => {
    setOpen(!open)
    dispatch(openDrawer({ drawerOpen: !open }))
  }

  // set media wise responsive drawer
  useEffect(() => {
    setOpen(!matchDownLG)
    dispatch(openDrawer({ drawerOpen: !matchDownLG }))
  }, [dispatch, matchDownLG])

  useEffect(() => {
    if (open !== drawerOpen) setOpen(drawerOpen)
  }, [drawerOpen, open])

  return (
    <Box sx={{ display: 'flex', width: '100%' }}>
      <Header open={open} handleDrawerToggle={handleDrawerToggle} />
      <Drawer open={open} handleDrawerToggle={handleDrawerToggle} />
      <Box
        component="main"
        sx={{
          backgroundColor: mainBgColor,
          width: `calc(100vw - ${drawerWidth}px)`,
          minHeight: `calc(100vh - ${headerHeight}px)`,
          height: `calc(100% - ${headerHeight}px)`,
          mt: `${headerHeight}px`,
          flexGrow: 1,
        }}
      >
        <Outlet />
      </Box>
    </Box>
  )
}

export default MainLayout
