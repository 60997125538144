import { lazy } from 'react'

// project import
import Loadable from 'components/Loadable'
import { MODULE } from 'constants/list-module'

const LandingPageListing = Loadable(lazy(() => import('pages/LandingPage/List')))
const LandingPageUpdate = Loadable(lazy(() => import('pages/LandingPage/FormUpdate')))
const LandingPageAdd = Loadable(lazy(() => import('pages/LandingPage/FormAdd')))

const LandingRoutes = [
  {
    path: 'landing_pages',
    element: <LandingPageListing />,
    code: MODULE.LANDINGPAGE,
  },
  {
    path: 'landing_page_editor/:id',
    element: <LandingPageUpdate />,
    code: MODULE.LANDINGPAGE,
  },
  {
    path: 'landing_page_editor',
    element: <LandingPageAdd />,
    code: MODULE.LANDINGPAGE,
  },
]

export default LandingRoutes
