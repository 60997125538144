import { useQuery } from '@tanstack/react-query'
import { getComments } from 'services/post.service'

const useQueryGetNumberComments = (filter: object) => {
  return useQuery({
    queryKey: ['number'],
    queryFn: async () => await getComments(filter),
  })
}

export default useQueryGetNumberComments
