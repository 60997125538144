import { ACTION } from 'constants/list-actions'
import useAuth from 'hooks/useAuth'
import PropTypes from 'prop-types'
import React from 'react'
import { useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom'
import { checkIsLocal } from 'utils/common'
import { isRead } from 'utils/permissions/action-permission'

const ProtectedAuth = ({ moduleCode, children }) => {
  const isAuthenticated = useAuth()
  const isAdmin = useSelector((state) => state?.user?.user?.is_admin)
  const permission = useSelector((state) => state?.user?.user?.permission) || {}

  if (isAuthenticated) {
    if (isAdmin) {
      return React.cloneElement(children, { actions: [ACTION.CREATE, ACTION.DELETE, ACTION.READ, ACTION.UPDATE] })
    } else {
      const keys = Object.keys(permission)
      const foundKey = keys.find((item) => item === moduleCode)
      const isAccessed = foundKey ? isRead(permission[foundKey]) : false
      if (isAccessed) {
        return React.cloneElement(children, { actions: permission[foundKey] })
      } else {
        return <Navigate to="/" />
      }
    }
  } else {
    if (checkIsLocal()) {
      return <Navigate to="/login" />
    } else {
      window.location.href = `${process.env.REACT_APP_FORLIFE_HOME}/login?redirect_url=${window.location.href}`
      return null
    }
  }
}

ProtectedAuth.propTypes = {
  allowedRoles: PropTypes.array,
}

export default ProtectedAuth
