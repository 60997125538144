import GradingIcon from '@mui/icons-material/Grading'
import { BIGMODULE } from 'constants/list-module'

const OrderManagement = {
  id: 'ordermanagement',
  icon: <GradingIcon />,
  title: 'Quản lí đơn hàng',
  url: '/order-management',
  role: ['*'],
  code: BIGMODULE.ORDER,
  type: 'single',
}

export default OrderManagement
