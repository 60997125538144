import React from 'react'
import PropTypes from 'prop-types'
import MainContent from 'components/MainContent'

function Home() {
  return (
    <MainContent>
      <div>Trang chủ</div>
    </MainContent>
  )
}

export default Home
