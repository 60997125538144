import { lazy } from 'react'

// project import
import Loadable from 'components/Loadable'
import { MODULE } from 'constants/list-module'

const LuckySpin = Loadable(lazy(() => import('pages/LuckySpin/Listing')))

const CreateSpin = Loadable(lazy(() => import('pages/LuckySpin/Create')))
const EditSpin = Loadable(lazy(() => import('pages/LuckySpin/Edit')))
const ListGamer = Loadable(lazy(() => import('pages/LuckySpin/Listing/ListGamer')))

const LuckySpinRoutes = [
  {
    path: 'lucky-spin',
    element: <LuckySpin />,
    code: MODULE.LUCKY_SPIN,
  },
  {
    path: 'lucky-spin/create',
    element: <CreateSpin />,
    code: MODULE.LUCKY_SPIN,
  },
  {
    path: 'lucky-spin/:id',
    element: <EditSpin />,
    code: MODULE.LUCKY_SPIN,
  },
  {
    path: 'lucky-spin/:id/list-gamer',
    element: <ListGamer />,
    code: MODULE.LUCKY_SPIN,
  },
]

export default LuckySpinRoutes