import axios from 'axios'
import fetchApi from './config'
import Cookies from 'utils/cookies'
import { POST } from 'constants/method'
let authUrl = process.env.REACT_APP_PORTAL_API

export const login = async (params: { username: string; password: string }) => {
  return fetchApi(process.env.REACT_APP_PORTAL_API + '/api/login', params, POST)
}

export const logout = async () => {
  return await fetchApi(process.env.REACT_APP_PORTAL_API + '/api/logout', {}, POST)
}

export const refreshToken = async () => {
  const refreshToken = await Cookies.getRefreshToken()

  if (!refreshToken) return Promise.reject('Refresh token not found')

  try {
    const res = await axios.post(
      `${authUrl}/api/token/refresh`,
      {},
      {
        headers: {
          Authorization: 'Bearer ' + refreshToken,
        },
      }
    )

    return res.data?.data
  } catch (error) {
    return null
  }
}
