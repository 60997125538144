import { LoadingButton } from '@mui/lab'
import { Box, FormControl, OutlinedInput } from '@mui/material'
import React, { useState } from 'react'

import './style.scss'

interface IProps {
  data: object
  loading: boolean
  onChange: Function
  onsubmit: Function
}

const ListingTableToolbar: React.FC<IProps> = ({ loading, onChange, onsubmit, data }) => {
  const [filter, setFilter] = useState<any>(data)
  const handleChangeFilter = (type: string, value: any) => {
    setFilter({
      ...filter,
      [type]: value,
    })
  }

  const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    onsubmit(filter)
  }

  return (
    <Box sx={{ margin: '8px 0' }}>
      <form onSubmit={handleFormSubmit}>
        <FormControl sx={{ width: 175 }}>
          <OutlinedInput
            value={filter?.search}
            onChange={(e) => handleChangeFilter('search', e.target.value)}
            fullWidth
            size="small"
            placeholder="Nhập tên, mô tả"
            sx={{
              backgroundColor: 'white',
            }}
          />
        </FormControl>
        <FormControl>
          <LoadingButton
            variant="contained"
            color="primary"
            type="submit"
            sx={{ ml: 1 }}
            disabled={loading}
            loading={loading}
          >
            Lọc
          </LoadingButton>
        </FormControl>
      </form>
    </Box>
  )
}

export default ListingTableToolbar
