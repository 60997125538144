import {
  Box,
  Checkbox,
  CircularProgress,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import { isArray, isObject, isUndefined } from 'lodash'
import { TableColumnType, TableRowType } from 'models/common'
import React from 'react'
import CustomNoRowsOverlay from './noRowOverlay'
import './style.scss'
interface IProps {
  loading?: boolean
  columns: TableColumnType[]
  data: Array<any>
  hasCheckAll?: boolean
  defaultCheckAll?: boolean
  onCheckAll?: Function
  minHeight?: number
}

const UiTable: React.FC<IProps> = ({
  loading,
  columns,
  data,
  hasCheckAll = true,
  defaultCheckAll = false,
  onCheckAll = () => { },
  minHeight = 320
}) => {
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '& tr': {
      zIndex: 1,
    },
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
    overflow: 'hidden',
  }))

  const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
    position: 'relative',
    overflow: 'auto',
  }))

  const getColumnsCount = () => {
    let colCount = 0

    if (isArray(columns)) {
      colCount = columns.length
    }

    if (isArray(data)) {
      data?.forEach((item) => {
        if (isArray(item) && item.length > colCount) {
          colCount = item.length
        }
      })
    }

    return colCount
  }

  const renderHeader = () => {
    return (
      <TableRow>
        {hasCheckAll ? (
          <TableCell padding="checkbox">
            <Checkbox
              color="primary"
              checked={defaultCheckAll}
              onChange={(e, checked) => {
                onCheckAll(checked)
              }}
            />
          </TableCell>
        ) : null}
        {columns?.map((column, idx) => (
          <TableCell
            key={`cell-${idx}`}
            align={column.align}
            padding={column.disablePadding ? 'none' : 'normal'}
            style={{
              whiteSpace: 'nowrap',
              fontWeight: 'bold',
              paddingLeft: column.disablePadding ? 0 : '',
              width: column.width
            }}
          >
            {column.name}
          </TableCell>
        ))}
      </TableRow>
    )
  }

  const renderBody = () => {
    const colCount = getColumnsCount()
    const rows: Array<Array<TableRowType>> = []

    data?.forEach((row: TableRowType, i) => {
      if (isArray(row)) {
        rows[i] = []

        for (let j = 0; j < colCount; j++) {
          rows[i][j] = !isUndefined(row[j]) ? row[j] : ''
        }
      }
    })

    if (rows.length > 0 && !loading) {
      return rows.map((row, idx) => (
        <StyledTableRow key={idx} tabIndex={-1}>
          {row.map((r: TableRowType, idx: number) => {
            if (isObject(r) && !React.isValidElement(r)) {
              return (
                <TableCell
                  key={idx}
                  align={r.align || 'left'}
                  width={(r.style || {}).width}
                  style={{
                    ...r.style,
                  }}
                >
                  {r.data}
                </TableCell>
              )
            } else {
              return <TableCell key={idx}>{r}</TableCell>
            }
          })}
        </StyledTableRow>
      ))
    } else if (rows.length > 0 && loading) {
      return (
        <StyledTableRow>
          <TableCell colSpan={hasCheckAll ? colCount + 1 : colCount}>
            <Box sx={{
              display: "flex", alignItems: "center", minHeight: minHeight + 'px', justifyContent: 'center'
            }}>
              < CircularProgress size={30} />
            </Box>
          </TableCell>
        </StyledTableRow >
      )
    }

    return (
      <StyledTableRow>
        <TableCell colSpan={hasCheckAll ? colCount + 1 : colCount} align="center">
          {loading ? <CircularProgress size={30} /> : <CustomNoRowsOverlay />}
        </TableCell>
      </StyledTableRow>
    )
  }

  return (
    <StyledTableContainer>
      <Table aria-labelledby="tableTitle" size="small" sx={{ maxWidth: '100%' }}>
        <TableHead className="header">{renderHeader()}</TableHead>
        <TableBody children={renderBody()}></TableBody>
      </Table>
    </StyledTableContainer>
  )
}

export default UiTable
