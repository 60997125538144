// icons
import ListIcon from '@mui/icons-material/List'
import ArticleIcon from '@mui/icons-material/Article'
import NumberNoti from 'pages/PostComment/components/NumberNoti'
import { BIGMODULE, MODULE } from 'constants/list-module'

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const post = {
  id: 'group-post',
  title: 'Bài viết',
  type: 'group',
  role: ['*'],
  icon: <ArticleIcon />,
  code: BIGMODULE.POST,
  children: [
    {
      id: 'posts-listing',
      url: '/posts',
      icon: <ListIcon fontSize="small" />,
      title: 'Tất cả bài viết',
      role: ['*'],
      code: MODULE.POST_POSTS,
    },
    {
      id: 'post-categories',
      url: '/posts/categories',
      icon: <ListIcon fontSize="small" />,
      title: 'Chuyên mục',
      role: ['*'],
      code: MODULE.POST_CATEGORY,
    },
    {
      id: 'post-tags',
      url: '/posts/tags',
      icon: <ListIcon fontSize="small" />,
      title: 'Thẻ',
      role: ['*'],
      code: MODULE.POST_TAG,
    },
    {
      id: 'post-comment',
      url: '/posts/comments',
      icon: <ListIcon fontSize="small" />,
      title: 'Phản hồi',
      role: ['*'],
      code: MODULE.POST_COMMENT,
      numberNoti: <NumberNoti />,
    },
    {
      id: 'post-setting',
      url: '/posts/setting',
      icon: <ListIcon fontSize="small" />,
      title: 'Cài đặt bài viết',
      code: MODULE.POST_SETTING,
      role: ['*'],
    },
  ],
}

export default post
