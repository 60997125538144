import { lazy } from 'react'

// project import
import Loadable from 'components/Loadable'
import { MODULE } from 'constants/list-module'

const CkcPageListing = Loadable(lazy(() => import('pages/CKC/List')))
const CkcPageEditting = Loadable(lazy(() => import('pages/CKC/Edit')))

const CkcRouters = [
  {
    path: 'ckc_pages',
    element: <CkcPageListing />,
    code: MODULE.LANDINGPAGE,
  },
  {
    path: 'ckc_page_editor/:id',
    element: <CkcPageEditting />,
    code: MODULE.LANDINGPAGE,
  },

]

export default CkcRouters
