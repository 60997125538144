import LinkIcon from '@mui/icons-material/Link'
import { MODULE } from 'constants/list-module'

const ShortLink = {
  id: 'short-link',
  icon: <LinkIcon />,
  title: 'Short Link',
  url: '/short-link',
  role: ['*'],
  code: MODULE.SHORTLINK,
  type: 'single',
}

export default ShortLink
