// assets
import { TagsOutlined } from '@ant-design/icons'

// icons
import ListIcon from '@mui/icons-material/List'
import { BIGMODULE, MODULE } from 'constants/list-module'

const product = {
  id: 'product',
  icon: <TagsOutlined />,
  title: 'Sản phẩm',
  role: ['*'],
  type: 'group',
  code: BIGMODULE.PRODUCT,
  children: [
    {
      id: 'products',
      url: '/products',
      icon: <ListIcon fontSize="small" />,
      title: 'Sản phẩm',
      code: MODULE.PRODUCT,
      role: ['*'],
    },
    {
      id: 'categories',
      url: '/product/categories',
      icon: <ListIcon fontSize="small" />,
      title: 'Nhóm sản phẩm',
      code: MODULE.PRODUCT_CATEGORY,
      role: ['*'],
    },
    {
      id: 'product-options',
      url: '/product/options',
      icon: <ListIcon fontSize="small" />,
      title: 'Tùy chọn',
      code: MODULE.PRODUCT_OPTION,
      role: ['*'],
    },
    {
      id: 'product-setup-option',
      url: '/product/setup-option',
      icon: <ListIcon fontSize="small" />,
      title: 'Khai báo tùy chọn',
      code: MODULE.PRODUCT_ATTRIBUTE_GROUP,
      role: ['*'],
    },
    // {
    //   id: 'size-guide',
    //   url: '/size-guide',
    //   icon: <ListIcon fontSize="small" />,
    //   title: 'Hướng dẫn chọn size',
    //   code: MODULE.SIZE_GUIDE,
    //   role: ['*'],
    // },

    {
      id: 'product-tag',
      url: '/product/tags',
      icon: <ListIcon fontSize="small" />,
      code: MODULE.PRODUCT_TAG,
      title: 'Thẻ',
      role: ['*'],
    },
    {
      id: 'product-feed',
      url: '/product/product-feed',
      icon: <ListIcon fontSize="small" />,
      title: 'Xuất XML',
      code: MODULE.EXPORT_XML,
      role: ['*'],
    },
    {
      id: 'rating-review',
      url: '/product/rating-review',
      icon: <ListIcon fontSize="small" />,
      title: 'Đánh giá - Review',
      code: MODULE.PRODUCT_COMMENT,
      role: ['*'],
    },
  ],
}

export default product
