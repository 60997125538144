import { createSlice } from '@reduxjs/toolkit'
import { UserState } from '../../models/redux'
import { RootState } from '../store'

const initialState: UserState = {
  user: {
    id: '',
    role: {
      level: '',
      name: '',
    },
    is_admin: false,
    avatar: '',
    name: '',
    username: '',
    email: '',
    phone: '',
    bod: '',
    permission: {},
  },
  isLoading: false,
  isLoggedIn: false,
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    getUserToken: (state) => {
      return {
        ...state,
        isLoading: true,
      }
    },

    getUserTokenSuccess: (state, action) => {
      return {
        ...state,
        isLoading: false,
        isLoggedIn: true,
        user: action.payload,
      }
    },

    getUserTokenError: (state, action) => {
      return {
        ...state,
        isLoading: false,
        isLoggedIn: false,
      }
    },
  },
})
export const { actions: userActions, reducer: userReducer } = userSlice
export const userSlector = (state: RootState) => state.user
