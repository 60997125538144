import { AsYouType, isValidPhoneNumber } from 'libphonenumber-js'
import moment from 'moment-timezone'

export const formatDatetimeUTC = (d) => {
  if (d) {
    return moment(d).tz('UTC').format('DD/MM/YYYY HH:mm:ss')
  }
  return ''
}

export const formatDatetime = (d) => {
  if (d) {
    return moment(d).format('DD/MM/YYYY HH:mm:ss')
  }
  return ''
}

export const formatDate = (d) => {
  if (d) {
    return moment(d).format('DD/MM/YYYY')
  }
  return ''
}

export const formatDatetimeEn = (d) => {
  if (d) {
    return moment(d).format('YYYY-MM-DD HH:mm:ss')
  }
  return ''
}

export const formatDateEn = (d, format = 'YYYY-MM-DD') => {
  if (d) {
    return moment(d).format(format)
  }
  return ''
}

export const timeSince = (date) => {
  var seconds = Math.floor((new Date() - date) / 1000)
  var interval = seconds / 31536000

  if (interval > 1) {
    return Math.floor(interval) + ' năm trước'
  }
  interval = seconds / 2592000
  if (interval > 1) {
    return Math.floor(interval) + ' tháng trước'
  }
  interval = seconds / 86400
  if (interval > 1) {
    return Math.floor(interval) + ' ngày trước'
  }
  interval = seconds / 3600
  if (interval > 1) {
    return Math.floor(interval) + ' giờ trước'
  }
  interval = seconds / 60
  if (interval > 1) {
    return Math.floor(interval) + ' phút trước'
  }
  return Math.floor(seconds) + ' giây trước'
}

export const formatPhoneToInternational = (value, national = 'VN') => {
  if (!isValidPhoneNumber(value, national)) return 'invalid'

  const asYouType = new AsYouType(national)
  asYouType.input(value)
  return asYouType.getNumber().number
}

export const format_currency = (price) => {
  if (price) {
    const formatter = new Intl.NumberFormat('en-US', {
      currency: 'VND',
    })
    return formatter.format(price)
  } else {
    return 0
  }
}

// export function formatTimezone(date: any, local: boolean = false) {
//   if (local) return moment(date).format(appConfig.formatDatetime)
//   return moment(date).tz(appConfig.timezone).format(appConfig.formatDatetime)
// }
