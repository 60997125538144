import { lazy } from 'react'

// project import
import Loadable from 'components/Loadable'
import { MODULE } from 'constants/list-module'
const ShippingFeesManagement = Loadable(lazy(() => import('pages/ShippingFeesManagement/Listing')))
const ShippingFeesCreate = Loadable(lazy(() => import('pages/ShippingFeesManagement/Create')))
const ShippingFeesManagementDetail = Loadable(lazy(() => import('pages/ShippingFeesManagement/Edit')))


const ShippingFeesManagementRoutes = [
  {
    path: 'shipping-fees-management',
    element: <ShippingFeesManagement />,
    code: MODULE.SHIPPING_FEES,
  },
  {
    path: 'shipping-fees-create',
    element: <ShippingFeesCreate />,
    code: MODULE.SHIPPING_FEES,
  },
  {
    path: 'shipping-fees-management/:id',
    element: <ShippingFeesManagementDetail />,
    code: MODULE.SHIPPING_FEES,
  },

]

export default ShippingFeesManagementRoutes
