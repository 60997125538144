export const HTTP_STATUS = {
  SUCCESS: 200,
  CREATED_SUCCESS: 201,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  INTERNAL_SERVER_ERROR: 500,
  SERVICE_UNAVAILABLE: 503,
  OTHER: -1,
  MULTIPLE_CHOICES: 300,
}

export const MESSAGE_TYPES = {
  SUCCESS: 'success',
  ERROR: 'error',
}

export const CROSS_TYPES = {
  0: 'Đơn hàng',
  1: 'Sản phẩm điều kiện',
  2: "Mã QR"
}

export const PROGRAM_TYPES = {
  "MiniGame": 'Mini game',
  "NewApp": 'Cài app tặng code',
  "PromotionNews": "Thông tin ưu đãi"
}


export const URLs = {}
