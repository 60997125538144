import React from 'react'
import PropTypes from 'prop-types'
import { Box, Drawer, Typography, styled as MUIStyled, useTheme } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'

const MainDrawer = MUIStyled((props) => {
  const { children, title, className, onClose, anchor = 'right', ...other } = props
  const theme = useTheme()

  return (
    <Drawer
      className={['drawer-main', className].join(' ')}
      anchor={anchor}
      PaperProps={{
        xs: {
          width: '100%',
        },
        sm: {
          width: '50%',
        },
      }}
      onClose={onClose}
      {...other}
    >
      <Box className="drawer__head">
        <ArrowBackIcon className="drawer__head__back" onClick={onClose} />
        <Typography variant="h4" className="drawer__head__title">
          {title}
        </Typography>
      </Box>
      <Box
        className="drawer__content"
        sx={{
          width: 700,
          [theme.breakpoints.up('xs')]: {
            width: '100%',
            px: 1,
          },
        }}
      >
        <Box>{children}</Box>
      </Box>
    </Drawer>
  )
})(({ theme }) => ({
  zIndex: theme.zIndex.modal,
  '& .drawer__head': {
    height: '58px',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderBottom: '0.5px solid #dddd',
    textTransform: 'uppercase',

    '&__back': {
      position: 'absolute',
      left: 10,
      cursor: 'pointer',
    },
    '&__title': {},
  },

  '& .drawer__content': {
    overflow: 'hidden',
    height: 'calc(100vh - 58px)',

    '& > div': {
      overflow: 'auto',
      height: '100%',
      paddingTop: '20px',
      paddingBottom: '30px',
    },
  },
}))

MainDrawer.propTypes = {
  title: PropTypes.string,
}

MainDrawer.defaultProps = {
  title: 'Tiêu đề mẫu',
}

export default MainDrawer
