import { pricingApiEndpoint } from 'config'
import { transformObjToUrlQuery } from '../utils/common'
import fetchApi from './config'
import { DELETE, GET, PATCH, POST, PUT } from 'constants/method'

// Products
export const getProduct = async (id: string) => {
  return fetchApi(`/products/${id}`)
}

// skus[]=2209217121411&skus[]=B8SCF614B-001
export const getProducts = async (filter: object) => {
  return fetchApi(`/products${transformObjToUrlQuery(filter)}`)
}

export const uploadFileListProduct = async (file: File) => {
  let formData = new FormData()
  formData.append('file', file)

  return fetchApi('/products/import', formData, POST, {
    'content-type': 'multipart/form-data',
  })
}

export const createProduct = async (data: object) => {
  return fetchApi(`/products`, data, POST)
}

export const updateProduct = async (id: string, data: object) => {
  return fetchApi(`/products/${id}`, data, PUT)
}

export const deleteProduct = async (id: string) => {
  return fetchApi(`/products/${id}`, {}, DELETE)
}

export const getProductsByCondition = async (data: object) => {
  return fetchApi('/products/products', data, POST)
}

export const uploadFileExcel = async (file: File) => {
  let formData = new FormData()
  formData.append('file', file)

  return fetchApi('/catalog/importExcel', formData, POST, {
    'content-type': 'multipart/form-data',
  })
}

export const uploadFileCSV = async (file: File) => {
  let formData = new FormData()
  formData.append('file', file)

  return fetchApi('/catalog/importCsv/', formData, POST, {
    'content-type': 'multipart/form-data',
  })
}

// Variants
export const getProductVariant = async (id: string) => {
  return fetchApi(`/productVariants/${id}`)
}

export const updateProductVariants = async (data: object) => {
  return fetchApi(`/productVariants/bulk-change-image`, data, POST)
}

export const updateStatusVariant = async (id: string, data: object) => {
  return fetchApi(`/productVariants/${id}`, data, PUT)
}

// Options
export const createProductOption = async (data: object) => {
  return fetchApi(`/productOptions`, data, POST)
}

export const updateProductOption = async (id: string, data: object) => {
  return fetchApi(`/productOptions/${id}`, data, PUT)
}

export const getProductOption = async (id: string, filter: object = {}) => {
  return fetchApi(`/productOptions/${id}${transformObjToUrlQuery(filter)}`)
}

export const getProductOptionValues = async (id: string) => {
  return fetchApi(`/productOptionValues/${id}`)
}

export const getProductOptions = async (filter: object) => {
  return fetchApi(`/productOptions/${transformObjToUrlQuery(filter)}`)
}

export const deleteProductOption = async (id: string) => {
  return fetchApi(`/productOptions/${id}`, {}, DELETE)
}

export const getOptionAttributeSize = async (filter: object) => {
  return fetchApi(`v1/product-attribute-group/${transformObjToUrlQuery(filter)}`)
}

export const getOptionAttributeColor = async (filter: object) => {
  return fetchApi(`v1/product-attribute-group/${transformObjToUrlQuery(filter)}`)
}

export const createOptionAttribute = async (data: object) => {
  return fetchApi(`v1/product-attribute-group`, data, POST)
}

export const updateOptionAttribute = async (id: string, data: object) => {
  return fetchApi(`v1/product-attribute-group/${id}`, data, PUT)
}

// Categories
export const getCategories = async (filter: object) => {
  return fetchApi(`/catalog${transformObjToUrlQuery(filter)}`)
}

export const getCategoryById = async (id: string, filter: object) => {
  return fetchApi(`/catalog/category/${id}${transformObjToUrlQuery(filter)}`)
}

export const createCategory = async (data: object) => {
  return fetchApi(`/catalog`, data, POST)
}

export const updateCategory = async (id: string, data: object) => {
  return fetchApi(`/catalog/${id}`, data, PUT)
}

export const deleteCategory = async (id: string, data: object) => {
  return fetchApi(`/catalog/${id}`, data, DELETE)
}

export const getConditionOptions = async () => {
  return fetchApi('/catalog/condition', {}, GET)
}

export const getOptionConditionbyKey = async (key: string) => {
  return fetchApi(`/catalog/condition/${key}`, {}, GET)
}

export const getPinnedProductByCategoryId = async (id: string, filter: object) => {
  return fetchApi(`/catalog/${id}/pin_products${transformObjToUrlQuery(filter)}`, {}, GET)
}
// Tags
export const getTag = async (id: string) => {
  return fetchApi(`/productTags/${id}`)
}

export const getTags = async (filter: object) => {
  return fetchApi(`/productTags${transformObjToUrlQuery(filter)}`)
}

export const createTag = async (data: object) => {
  return fetchApi(`/productTags`, data, POST)
}

export const updateTag = async (id: string, data: object) => {
  return fetchApi(`/productTags/${id}`, data, PUT)
}

export const deleteTag = async (id: string, data: object) => {
  return fetchApi(`/productTags/${id}`, data, DELETE)
}

// Pricing
export const getPricingProducts = async (data: any) => {
  return fetchApi(`${pricingApiEndpoint}products`, data)
}

// export file excel
export const exportFileExcel = async (filter: object) => {
  return fetchApi(`/products/export${transformObjToUrlQuery(filter)}`)
}

// Change status products
export const changeStatusProducts = async (data: object) => {
  return fetchApi(`products/status`, data, PATCH)
}

//Change tag products
export const changeTagProducts = async (data: object) => {
  return fetchApi(`products/tags`, data, POST)
}

//Get type products
export const getTypeProducts = async () => {
  return fetchApi(`products/types/unique`)
}

//Get sex
export const getSex = async () => {
  return fetchApi(`productOptionValues/sex`)
}

//Best seller
export const bestSeller = async (data: object) => {
  return fetchApi(`products/top-sale-products`, data, POST)
}

export const getBestSeller = async () => {
  return fetchApi(`products/top-sale-products/list`, GET)
}

//Rating-Review

export const getRatingReviews = async (filter: object) => {
  return fetchApi(`/admin/rating-review/${transformObjToUrlQuery(filter)}`)
}

export const getDetailRating = async (id: string) => {
  return fetchApi(`/admin/rating-review/detail/${id}`)
}

export const deleteReplyRating = async (id: string, data: object) => {
  return fetchApi(`/admin/rating-review/${id}`, data, PATCH)
}

export const replyRating = async (id: string, data: object) => {
  return fetchApi(`/admin/rating-review/${id}`, data, PATCH)
}

export const updateReview = async (id: string, data: object) => {
  return fetchApi(`/admin/rating-review/${id}`, data, PATCH)
}

export const updateMultiRatingStatus = async (data: object) => {
  return fetchApi(`/admin/rating-review/update-comments`, data, POST)
}

//Add features products

export const addFeaturesProducts = async (data: object) => {
  return fetchApi(`/products/features`, data, PATCH)
}

//export XML

export const getListProductFeed = async (filter: object) => {
  return fetchApi(`products/product/feed${transformObjToUrlQuery(filter)}`)
}

// get inventory
export const getInventoryProduct = async (sku: string) => {
  return fetchApi(`products/product/feed/${sku}/inventory`)
}

export const exportProductFeed = async (filter: object) => {
  return fetchApi(`products/export/productfeed${transformObjToUrlQuery(filter)}`)
}

export const getListExportXML = async (filter: object) => {
  return fetchApi(`product-feed${transformObjToUrlQuery(filter)}`)
}

export const getExportXMLById = async (id: string, filter: object) => {
  return fetchApi(`product-feed/${id}${transformObjToUrlQuery(filter)}`)
}

export const updateStatusExportXMLById = async (id: string, data: object) => {
  return fetchApi(`product-feed/${id}`, data, PATCH)
}
export const deleteXML = async (id: string, data: object) => {
  return fetchApi(`/product-feed/${id}`, data, DELETE)
}
//size-guide

export const getListSizeGuide = async (filter: object) => {
  return fetchApi(`products/product/feed${transformObjToUrlQuery(filter)}`)
}

export const updateSizeGuide = async (data: object) => {
  return fetchApi(`/admin/rating-review/update-comments`, data, POST)
}

export const getSizeGuideByID = async (id: string, filter: object) => {
  return fetchApi(`/catalog/${id}/pin_products`, {}, GET)
}

export const deleteSizeGuideByID = async (id: string, data: object) => {
  return fetchApi(`/catalog/${id}`, data, DELETE)
}

//parameter

export const getListParameter = async (filter: object) => {
  return fetchApi(`products/product/feed${transformObjToUrlQuery(filter)}`)
}

export const updateParameter = async (data: object) => {
  return fetchApi(`/admin/rating-review/update-comments`, data, POST)
}

export const getParameterByID = async (id: string, filter: object) => {
  return fetchApi(`/catalog/${id}/pin_products`, {}, GET)
}

export const deleteParameterByID = async (id: string, data: object) => {
  return fetchApi(`/catalog/${id}`, data, DELETE)
}

export const updateListReviews = async (data: any) => {
  return fetchApi(`/admin/rating-review/reviews`, data, POST)
}

// Original category

export const getListOriginalCategory = (filter: object) => {
  return fetchApi(`admin/v1/original-categories${transformObjToUrlQuery(filter)}`)
}

export const updateKeywordCategrory = (data: { id: string, keyword: object }) => {
  return fetchApi(`admin/v1/original-categories/${data?.id}`, data?.keyword, PUT)
}

export const updateMultiKeywordCategory = (data: any) => {
  return fetchApi('admin/v1/original-categories/bulk-update', data, POST)
}

//async images
export const asyncImages = (data: any) => {
  return fetchApi('productVariants/sync-images', data, POST)
}