export const StoreStatusType = {
  INIT: 1,
  OPEN: 2,
  CLOSE: 3,
  STOP: 4,
}

StoreStatusType[StoreStatusType.INIT] = {
  text: 'Sắp mở',
  color: 'primary',
}

StoreStatusType[StoreStatusType.OPEN] = {
  text: 'Mở cửa',
  color: 'success',
}

StoreStatusType[StoreStatusType.CLOSE] = {
  text: 'Đóng cửa',
  color: 'error',
}

StoreStatusType[StoreStatusType.STOP] = {
  text: 'Dừng hoạt động',
  color: 'warning',
}
