import { Chip } from '@mui/material'
import useQueryGetNumberComments from 'hooks/useQueryGetNumberComments'
import { useEffect } from 'react'
export default function NumberNoti() {
  const filter = { page: 1, per_page: 0, status: 'pending' }
  const { data, refetch } = useQueryGetNumberComments(filter)
  const callbackTime = 10000

  useEffect(() => {
    refetch()
    const intervalId = setInterval(refetch, callbackTime)
    return () => clearInterval(intervalId)
  }, [])

  return data?.pagination?.total ? (
    <Chip label={data?.pagination?.total} variant="contained" color="error" size="small" sx={{ float: 'right' }} />
  ) : null
}
