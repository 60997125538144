import { useRef, useState } from 'react'

// material-ui
import { useTheme } from '@mui/material/styles'
import {
  Badge,
  Box,
  ClickAwayListener,
  Divider,
  IconButton,
  List,
  ListItemButton,
  ListItemText,
  Paper,
  Popper,
  Typography,
  useMediaQuery,
  Stack,
  Tooltip,
} from '@mui/material'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'
import DoneAllIcon from '@mui/icons-material/DoneAll'
// project import
import MainCard from 'components/MainCard'
import Transitions from 'components/@extended/Transitions'

// assets
import { BellOutlined } from '@ant-design/icons'
import { useEffect } from 'react'

// Services
import * as userService from 'services/user.service'
import { timeSince } from 'utils/formater'

import './style.scss'
import { Navigate, useNavigate } from 'react-router-dom'
import { truncateString } from 'utils/common'

// sx styles
const avatarSX = {
  width: 36,
  height: 36,
  fontSize: '1rem',
}

const actionSX = {
  mt: '6px',
  ml: 1,
  top: 'auto',
  right: 'auto',
  alignSelf: 'flex-start',

  transform: 'none',
}

// ==============================|| HEADER CONTENT - NOTIFICATION ||============================== //

const Notification = () => {
  const [data, setData] = useState([])
  const [pagination, setPagination] = useState({})
  const [openPopup, setOpenPopup] = useState(false)
  const [popupData, setPopupData] = useState(null)
  const [unreadTotal, setUnreadTotal] = useState(0)
  const [initializing, setInitializing] = useState(true)
  let navigate = useNavigate()

  const theme = useTheme()
  const matchesXs = useMediaQuery(theme.breakpoints.down('md'))

  const anchorRef = useRef(null)
  const [open, setOpen] = useState(false)

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleMarkAllDone = async () => {
    try {
      await userService.notifications({ read_all: true })
      setUnreadTotal(0)
      setData(data.map((item) => ({ ...item, is_read: true })))
      setOpen(false)
    } catch (error) {
      console.log(error)
      setOpen(false)
    }
  }

  const fetchDataUnread = async () => {
    try {
      const res = await userService.notifications({ is_read: false })
      setUnreadTotal(res.pagination?.total)
    } catch (error) {
      console.log(error)
    }
  }

  const fetchData = async (page = 1) => {
    try {
      const res = await userService.notifications({ page, per_page: 10 })
      setData([...data, ...res.data])
      setPagination(res.pagination)
      setInitializing(false)
    } catch (error) {
      console.log(error)
    }
  }

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }
    setOpen(false)
  }

  const handleChangeNextPage = () => {
    fetchData(pagination?.current_page + 1)
  }

  const handleChangeToReaded = (id) => {
    const newData = data.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          is_read: true,
        }
      }

      return item
    })

    setData(newData)
    setUnreadTotal(unreadTotal - 1)
  }

  const handleClickNotification = async (val) => {
    handleChangeToReaded(val.id)

    if (val.ticket?.id) {
      await userService.getNotification(val.id)
      navigate(`/tickets/${val.ticket?.id}`)
    } else {
      setOpenPopup(true)
      setPopupData(val)
    }

    setOpen(false)
  }

  useEffect(() => {
    if (initializing) {
      fetchData()
      fetchDataUnread()
    }
  }, [])

  const renderItem = (item) => {
    const createDate = new Date(item.created_at.replace(' ', 'T'))

    return (
      <ListItemButton
        key={`notification-${item.id}`}
        onClick={() => handleClickNotification(item)}
        style={{
          borderBottom: '1px dashed #ccc',
          paddingLeft: 0,
          paddingRight: 0,
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          spacing={1}
          style={{
            paddingLeft: 5,
            paddingRight: 10,
          }}
        >
          <Box>
            {item.is_read ? (
              <FiberManualRecordIcon
                sx={{
                  mt: 1,
                  color: 'transparent',
                  fontSize: 10,
                }}
              />
            ) : (
              <FiberManualRecordIcon
                color="success"
                sx={{
                  mt: 1,
                  fontSize: 10,
                }}
              />
            )}
          </Box>
          <ListItemText
            primary={
              <Typography variant="h6">
                <Box sx={{ mb: 0.8 }}>
                  <Stack direction="row" justifyContent="space-between" alignItems="flex-end">
                    <b>{item.title}</b>{' '}
                  </Stack>
                </Box>
                <Box>{truncateString(item.sub_title, 100)}</Box>
                <Box sx={{ mt: 1 }}>
                  <Typography sx={{ fontSize: '.85rem', whiteSpace: 'nowrap', fontWeight: 'bold', color: 'GrayText' }}>
                    {timeSince(createDate)}
                  </Typography>
                </Box>
              </Typography>
            }
          />
        </Stack>
      </ListItemButton>
    )
  }

  const iconBackColorOpen = 'grey.300'
  const iconBackColor = 'grey.100'

  return (
    <Box sx={{ flexShrink: 0, ml: 0.75 }}>
      <IconButton
        disableRipple
        color="secondary"
        sx={{ color: 'text.primary', bgcolor: open ? iconBackColorOpen : iconBackColor }}
        aria-label="open profile"
        ref={anchorRef}
        aria-controls={open ? 'profile-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        <Badge badgeContent={unreadTotal} color="primary">
          <BellOutlined />
        </Badge>
      </IconButton>
      <Popper
        placement={matchesXs ? 'bottom' : 'bottom-end'}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [matchesXs ? -5 : 0, 9],
              },
            },
          ],
        }}
      >
        {({ TransitionProps }) => (
          <Transitions type="fade" in={open} {...TransitionProps}>
            <Paper
              sx={{
                width: '100%',
                minWidth: 285,
                maxWidth: 420,
                [theme.breakpoints.down('md')]: {
                  maxWidth: 285,
                },
              }}
            >
              <ClickAwayListener onClickAway={handleClose}>
                <MainCard
                  title="THÔNG BÁO"
                  elevation={0}
                  border={false}
                  content={false}
                  secondary={
                    <>
                      {unreadTotal ? (
                        <Tooltip title="Đánh dấu đã đọc">
                          <IconButton size="small" onClick={handleMarkAllDone}>
                            <DoneAllIcon color="success" />
                          </IconButton>
                        </Tooltip>
                      ) : null}
                    </>
                  }
                >
                  <List
                    component="nav"
                    sx={{
                      maxHeight: 420,
                      overflow: 'auto',
                      p: 0,
                      '& .MuiListItemButton-root': {
                        py: 0.5,
                        '& .MuiAvatar-root': avatarSX,
                        '& .MuiListItemSecondaryAction-root': { ...actionSX, position: 'relative' },
                      },
                    }}
                  >
                    {data.length > 0 ? (
                      data.map((item) => renderItem(item))
                    ) : (
                      <Box sx={{ p: 1, textAlign: 'center' }}> Chưa có thông báo</Box>
                    )}
                  </List>
                  {pagination?.current_page < pagination.last_page && (
                    <>
                      <Divider />
                      <ListItemButton
                        sx={{ textAlign: 'center', py: `${12}px !important` }}
                        onClick={() => handleChangeNextPage()}
                      >
                        <ListItemText
                          primary={
                            <Typography variant="h6" color="primary">
                              Xem thêm
                            </Typography>
                          }
                        />
                      </ListItemButton>
                    </>
                  )}
                </MainCard>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>
    </Box>
  )
}

export default Notification
