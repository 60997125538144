import { styled as MUIStyled, Typography } from '@mui/material'
import { Box } from '@mui/system'
import Field, { IField } from './Field'
interface Props {
  className?: string
  title: string
  items: IField[]
  setFieldValue(name: string, value: string): void
  label_vertical?: boolean
}
const Fields = MUIStyled(({ className, label_vertical, title, items, setFieldValue, ...other }: Props): JSX.Element => {
  return (
    <Box className={[className].join(' ')} {...other}>
      <Typography variant="h5">{title}</Typography>
      <Box className="fields_div">
        {items.map((item: IField, index: number) => (
          <Field
            key={index + ''}
            type={item.type ? item.type : 'text'}
            value={item.value || ''}
            onChange={(value: string) => item.name && setFieldValue(item.name, value)}
            label={item.label}
            placeholder={item.placeholder}
            className={`input ${index != 0 ? 'right' : ''} `}
            error={item.error}
          />
        ))}
      </Box>
    </Box>
  )
})(({ theme, label_vertical }) => {
  return {
    display: 'flex',
    flexDirection: label_vertical ? 'column' : 'row',
    // borderRadius: theme.spacing(1),
    marginTop: theme.spacing(3),
    '& .fields_div': {
      display: 'flex',
      flexDirection: 'row',
      marginTop: label_vertical ? theme.spacing(1) : 0,
      marginLeft: label_vertical ? 0 : theme.spacing(5),
      '& .input': {
        marginTop: theme.spacing(0),
      },
      '& .right': {
        marginLeft: theme.spacing(6),
      },
    },
  }
})

export default Fields
