import { call, put, takeLatest } from 'redux-saga/effects'
import * as userService from '../../services/user.service'
import { userActions } from '../slices/userSlice'

/**
 * Get user info by token
 */
function* getUserToken({ payload }: any): Generator {
  try {
    const res = yield call(userService.getUserToken)
    yield put(userActions.getUserTokenSuccess(res))

    if (payload && payload.onSuccess) {
      payload.onSuccess(res)
    }
  } catch (error) {
    yield put(userActions.getUserTokenError(error))
    if (payload && payload.onError) {
      payload.onError(error)
    }
  }
}

export default function* userSaga() {
  yield takeLatest(userActions.getUserToken, getUserToken)
}
