import { lazy } from 'react'

// project import
import Loadable from 'components/Loadable'
import { MODULE } from 'constants/list-module'

const StorePageListing = Loadable(lazy(() => import('pages/Store/Listing/index')))

const StoreRoutes = [
  {
    path: 'stores',
    element: <StorePageListing />,
    code: MODULE.STORE,
  },
]

export default StoreRoutes
