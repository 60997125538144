import { TableCell, TableHead, TableRow } from '@mui/material'

export interface HeadCell {
  id: string
  label: string
  disablePadding: boolean
  numeric: boolean
}
interface Props {
  headCells: HeadCell[]
  numSelected: number
  rowCount: number
}
function ListingTableHead({ headCells, numSelected, rowCount }: Props) {
  return (
    <TableHead sx={{ height: '50px' }}>
      <TableRow>
        {headCells.map((headCell: HeadCell) => (
          <TableCell
            // padding="checkbox"
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            //   sortDirection={orderBy === headCell.id ? order : false}
            style={{
              whiteSpace: 'nowrap',
              fontWeight: 'bold',
              paddingLeft: 10,
            }}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

export default ListingTableHead
