import { lazy } from 'react'

// project import
import Loadable from 'components/Loadable'
import { MODULE } from 'constants/list-module'

const OrderManagementPage = Loadable(lazy(() => import('pages/OrderManagement/Listing/index')))

const OrderManagementRoutes = [
  {
    path: 'order-management',
    element: <OrderManagementPage />,
    code: MODULE.ORDER,
  },
]

export default OrderManagementRoutes
