import LocalAtmIcon from '@mui/icons-material/LocalAtm'
import { MODULE } from 'constants/list-module'

const ShippingFeesManagement = {
  id: 'shipping-fees-management',
  icon: <LocalAtmIcon />,
  title: 'Quản lí phí vận chuyển',
  url: '/shipping-fees-management',
  role: ['*'],
  code: MODULE.SHIPPING_FEES,
  type: 'single',
}

export default ShippingFeesManagement
