import { DELETE, GET, POST } from 'constants/method'
import { transformObjToUrlQuery } from '../utils/common'
import fetchApi from './config'
import dayjs from 'dayjs'
import md5 from 'md5'

export const upload = async (file: File) => {
  return fetchApi(`/common/upload`, file, POST)
}

export const uploadCkeditor = async (file: File) => {
  return fetchApi(`/common/ckeditor-upload`, file, POST)
}

export const createAttachment = async (file: File & { folder_type?: string }, alt?: string, folder_type?: string) => {
  let formData = new FormData()
  formData.append('file', file)
  formData.append('folder_type', folder_type || 'product')
  if (alt) formData.append('redirect_link', alt)
  return fetchApi(`/common/attachments/add`, formData, POST)
}

export const createVideo = async (file: File, alt?: string, folder_type?: string) => {
  let formData = new FormData()
  formData.append('file', file)
  return fetchApi(`/common/upload/video`, formData, POST)
}

export const deleteAttachment = async (id: string) => {
  return fetchApi(`/common/attachments/${id}`, {}, DELETE)
}

export const getAttachments = async (filter: object) => {
  return fetchApi(`/common/attachments${transformObjToUrlQuery(filter)}`, {}, GET)
}

export const getCatalog = async (filter: object) => {
  return fetchApi(`/catalog${transformObjToUrlQuery(filter)}`)
}

export const getCondition = async (filter: object) => {
  return fetchApi(`/catalog/condition${transformObjToUrlQuery(filter)}`)
}

export const getLocation = async (filter: object) => {
  const X_APP_KEY = process.env.REACT_APP_CART_SECRET_KEY || "OfeUUqK1SbEvbIYVnnupRjzhMptJn"
  const headers: any = {};
  const timestamp = dayjs().valueOf();
  const signature = md5(X_APP_KEY + timestamp);
  headers["timestamp"] = timestamp;
  headers["signature"] = signature;
  return fetchApi(`${process.env.REACT_APP_CART_URL}/orders/locations${transformObjToUrlQuery(filter)}`, {}, GET, headers)
}