import { lazy } from 'react'

// project import
import Loadable from 'components/Loadable'
import { Navigate } from 'react-router-dom'
import { MODULE } from 'constants/list-module'

const Appearance = Loadable(lazy(() => import('pages/WebSetting')))
const Setting = Loadable(lazy(() => import('pages/Setting')))
const HomeSetting = Loadable(lazy(() => import('pages/WebSetting/HomeSetting/index')))
const FlashSaleSetting = Loadable(lazy(() => import('pages/WebSetting/FlashSaleSetting')))
const ProductSetting = Loadable(lazy(() => import('pages/WebSetting/ProductSetting')))
const NotiSetting = Loadable(lazy(() => import('pages/NotiSetting/Listing')))
const CreateNoti = Loadable(lazy(() => import('pages/NotiSetting/Create')))
const EditNoti = Loadable(lazy(() => import('pages/NotiSetting/Edit')))
const PromotionSetting = Loadable(lazy(() => import('pages/PromotionSetting/Listing')))
const CreatePromotion = Loadable(lazy(() => import('pages/PromotionSetting/Create')))
const EditPromotion = Loadable(lazy(() => import('pages/PromotionSetting/Edit')))
const AutoNoti = Loadable(lazy(() => import('pages/AutoNotiSetting/Listing/index')))
const EditAutoNoti = Loadable(lazy(() => import('pages/AutoNotiSetting/Edit/index')))
const Voucher = Loadable(lazy(() => import('pages/Voucher/index')))

const WebSettings = [
  {
    path: 'appearance-setting',
    element: <Appearance />,
    code: MODULE.CONFIGS,
    children: [
      {
        path: '',
        element: <Navigate to="home" replace />,
        code: MODULE.CONFIGS,
      },
      {
        path: 'general',
        element: <HomeSetting />,
        code: MODULE.CONFIGS,
      },
      {
        path: 'home',
        element: <HomeSetting />,
        code: MODULE.CONFIGS,
      },
      {
        path: 'flashsale',
        element: <FlashSaleSetting />,
        code: MODULE.CONFIGS,
      },
      {
        path: 'product',
        element: <ProductSetting />,
        code: MODULE.CONFIGS,
      },
    ],
  },
  {
    path: 'settings',
    element: <Setting />,
    code: MODULE.CONFIGS,
  },
  {
    path: 'noti-setting',
    element: <NotiSetting />,
    code: MODULE.NOTIFICATIONS,
  },
  {
    path: 'noti-setting/create',
    element: <CreateNoti />,
    code: MODULE.NOTIFICATIONS,
  },
  {
    path: 'noti-setting/edit/:id',
    element: <EditNoti />,
    code: MODULE.NOTIFICATIONS,
  },
  {
    path: 'auto-noti-setting',
    element: <AutoNoti />,
    code: MODULE.AUTO_NOTIFICATIONS,
  },
  {
    path: 'auto-noti-setting/edit/:id',
    element: <EditAutoNoti />,
    code: MODULE.AUTO_NOTIFICATIONS,
  },
  {
    path: 'promotion-setting',
    element: <PromotionSetting />,
    code: MODULE.PROMOTIONNEWS,
  },
  {
    path: 'promotion-setting/create',
    element: <CreatePromotion />,
    code: MODULE.PROMOTIONNEWS,
  },
  {
    path: 'promotion-setting/edit/:id',
    element: <EditPromotion />,
    code: MODULE.PROMOTIONNEWS,
  },
  {
    path: 'voucher',
    element: <Voucher />,
    code: MODULE.VOUCHER,
  },
]

export default WebSettings
