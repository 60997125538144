import { lazy } from 'react'

// project import
import Loadable from 'components/Loadable'
import { Navigate } from 'react-router-dom'
import { MODULE } from 'constants/list-module'
const PostListing = Loadable(lazy(() => import('pages/Post/Listing')))
const PostFormAdd = Loadable(lazy(() => import('pages/Post/FormAdd')))
const PostFormUpdate = Loadable(lazy(() => import('pages/Post/FormUpdate')))

const PostCategoryListing = Loadable(lazy(() => import('pages/PostCategory/Listing')))
const PostCategoryFormUpdate = Loadable(lazy(() => import('pages/PostCategory/FormUpdate')))
const PostCategoryFormAdd = Loadable(lazy(() => import('pages/PostCategory/FormAdd')))

const PostTagFormUpdate = Loadable(lazy(() => import('pages/PostTag/FormUpdate')))
const PostTagListing = Loadable(lazy(() => import('pages/PostTag/Listing')))

const PostCommentListing = Loadable(lazy(() => import('pages/PostComment/Listing')))
const PostCommentFormUpdate = Loadable(lazy(() => import('pages/PostComment/FormUpdate')))
const PostSettingLayOut = Loadable(lazy(() => import('pages/PostSetting/index')))
const HotPosts = Loadable(lazy(() => import('pages/PostSetting/HotPosts/HotPosts')))

const PostPinnedForm = Loadable(lazy(() => import('pages/PostSetting/PostPinned')))
const HomePostPinnedForm = Loadable(lazy(() => import('pages/PostSetting/HomePinned')))
const CategoriesSetting = Loadable(lazy(() => import('pages/PostSetting/CategoriesSetting')))

const PostRoutes = [
  {
    path: 'posts',
    element: <PostListing />,
    code: MODULE.POST_POSTS,
  },
  {
    path: 'post_new',
    element: <PostFormAdd />,
    code: MODULE.POST_POSTS,
  },
  {
    path: 'posts/:id',
    element: <PostFormUpdate />,
    code: MODULE.POST_POSTS,
  },
  {
    path: 'posts/categories',
    element: <PostCategoryListing />,
    code: MODULE.POST_CATEGORY,
  },
  {
    path: 'posts/categories_new',
    element: <PostCategoryFormAdd />,
    code: MODULE.POST_CATEGORY,
  },
  {
    path: 'posts/categories/:id',
    element: <PostCategoryFormUpdate />,
    code: MODULE.POST_CATEGORY,
  },
  {
    path: 'posts/tags',
    element: <PostTagListing />,
    code: MODULE.POST_TAG,
  },
  {
    path: 'posts/tags/:id',
    element: <PostTagFormUpdate />,
    code: MODULE.POST_TAG,
  },
  {
    path: 'posts/comments',
    element: <PostCommentListing />,
    code: MODULE.POST_COMMENT,
  },
  {
    path: 'posts/comments/:id',
    element: <PostCommentFormUpdate />,
    code: MODULE.POST_COMMENT,
  },
  {
    path: 'posts/setting',
    element: <PostSettingLayOut />,
    code: MODULE.POST_SETTING,
    children: [
      {
        path: '',
        element: <Navigate to="category" replace />,
        code: MODULE.POST_SETTING,
      },
      {
        path: 'hot-posts',
        element: <HotPosts />,
        code: MODULE.POST_SETTING,
      },
      {
        path: 'category',
        element: <PostPinnedForm />,
        code: MODULE.POST_SETTING,
      },
      {
        path: 'home-pinned',
        element: <HomePostPinnedForm />,
        code: MODULE.POST_SETTING,
      },
      {
        path: 'categories',
        element: <CategoriesSetting />,
        code: MODULE.POST_SETTING,
      },
    ],
  },
]

export default PostRoutes
