import { lazy } from 'react'

// project import
import Loadable from 'components/Loadable'
import { MODULE } from 'constants/list-module'

const PromotionLink = Loadable(lazy(() => import('pages/PromotionLink')))
const PromotionLinkForm = Loadable(lazy(() => import('pages/PromotionLink/components/PromotionLinkForm')))


const PromotionLinkRoutes = [
  {
    path: 'promotion-link',
    element: <PromotionLink />,
    code: MODULE.PROMOTION_LINK,
  },
  {
    path: 'promotion-link/create',
    element: <PromotionLinkForm />,
    code: MODULE.PROMOTION_LINK,
  },
  {
    path: 'promotion-link/:id',
    element: <PromotionLinkForm />,
    code: MODULE.PROMOTION_LINK,
  },
]

export default PromotionLinkRoutes