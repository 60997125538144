import ManageAccountsIcon from '@mui/icons-material/ManageAccounts'
import { MODULE } from 'constants/list-module'

const RoleManagement = {
  id: 'rolemanagement',
  icon: <ManageAccountsIcon />,
  title: 'Phân quyền',
  url: '/role-management',
  role: ['*'],
  code: MODULE.PERMISSION,
  type: 'single',
}

export default RoleManagement
