import React from 'react'
import PropTypes from 'prop-types'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography } from '@mui/material'
import { isString } from 'lodash'

ConfirmBox.propTypes = {
  title: PropTypes.string,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  showIcon: PropTypes.bool,
}

ConfirmBox.defaultProps = {
  title: 'Xác nhận',
  onOpen: () => {},
  onClose: () => {},
  onConfirm: () => {},
  onCancel: () => {},
  showIcon: true,
}

function ConfirmBox({ title, onClose, onCancel, onConfirm, showIcon, onOpen, children }) {
  return (
    <Dialog open={true} onClose={() => onClose()} maxWidth="md">
      <DialogTitle sx={{ py: 1 }}>
        <Typography sx={{ fontSize: '1rem', fontWeight: 'bold' }}>{title}</Typography>
      </DialogTitle>
      <DialogContent dividers>
        <Stack>
          {isString(children) ? (
            <span
              dangerouslySetInnerHTML={{
                __html: children,
              }}
            ></span>
          ) : (
            children
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="error"
          onClick={() => {
            onCancel()
            onClose()
          }}
        >
          Hủy
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            onConfirm()
            onClose()
          }}
        >
          Xác nhận
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmBox
