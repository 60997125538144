import { lazy } from 'react'

// project import
import Loadable from 'components/Loadable'
import { MODULE } from 'constants/list-module'

const ShortLinkPage = Loadable(lazy(() => import('pages/ShortLink/Listing')))
const ShortLinkFormCreate = Loadable(lazy(() => import('pages/ShortLink/Create')))
const ShortLinkFormEdit = Loadable(lazy(() => import('pages/ShortLink/Edit')))

const ShortLinkRoutes = [
  {
    path: 'short-link',
    element: <ShortLinkPage />,
    code: MODULE.SHORTLINK,
  },
  {
    path: 'short-link-create',
    element: <ShortLinkFormCreate />,
    code: MODULE.SHORTLINK,
  },
  {
    path: 'short-link/:id',
    element: <ShortLinkFormEdit />,
    code: MODULE.SHORTLINK,
  },
]

export default ShortLinkRoutes
