import axios from 'axios'
import { HTTP_STATUS } from 'constants/common'
import { authActions } from 'redux/slices/authSlice'
import Cookies from 'utils/cookies'
import { store } from '../redux/store'
import { refreshToken } from './auth.service'
import { DELETE, GET } from 'constants/method'
const TIMEOUT = 60000

async function getAuthorizationHeader() {
  const token = await Cookies.getToken()
  return token ? `Bearer ${token}` : undefined
}

const handleApiCallError = (error) => {
  const { status } = error.response || {}

  if ([HTTP_STATUS.UNAUTHORIZED, HTTP_STATUS.FORBIDDEN].includes(status)) {
    store.dispatch(authActions.logout())
  }
}

const handleAuthError = (error) => {
  if (error.response.status === HTTP_STATUS.UNAUTHORIZED) {
    store.dispatch(authActions.logout())
  }
}

function normalizeURL(url) {
  const firstDoubleSlashIndex = url.indexOf("//");
  if (firstDoubleSlashIndex === -1) {
    return url;
  }
  const firstPart = url.slice(0, firstDoubleSlashIndex + 2);
  const secondPart = url.slice(firstDoubleSlashIndex + 2);

  // Thay thế tất cả các dấu gạch chéo đôi bằng dấu gạch chéo đơn
  const normalizedSecondPart = secondPart.replace(/\/\//g, '/');


  return firstPart + normalizedSecondPart;
}

export const funcFetch = async (axiosInstance, endPoint, method, data, headers, options) => {
  const _endpoint = normalizeURL(endPoint)

  const max_time = options?.retry_time || 3
  let counter = 0

  axiosInstance.interceptors.response.use(
    async (response) => {
      const { status, data } = response

      if (status < HTTP_STATUS.MULTIPLE_CHOICES && data) {
        return data.data
      } else {
        console.log('data?.message', data?.message)
        throw new Error(data.message)
      }
    },
    async (error) => {
      const { config } = error
      console.log('data?.err', error)
      if (
        config?.url.indexOf('logout') === -1 &&
        counter < max_time &&
        error.response.status === HTTP_STATUS.UNAUTHORIZED
      ) {
        counter++

        try {
          const newToken = await refreshToken()

          if (newToken) {
            Cookies.setToken(newToken.access_token)
            config.headers.Authorization = `Bearer ${newToken.access_token}`

            return axiosInstance(config)
          } else {
            handleAuthError(error)
          }
        } catch (error) {
          console.log('errror1', error)

          handleApiCallError(error)
        }
      }

      return Promise.reject(error?.response?.data)
    }
  )

  const dataValue = [GET, DELETE].includes(method?.toLowerCase()) ? { data } : data

  return axiosInstance[method](_endpoint, dataValue, headers)
}

const fetchApi = async (endPoint = '', data, method = GET, headers = {}, url = process.env.REACT_APP_API_URL) => {
  const axiosInstance = axios.create({
    baseURL: url,
    headers: {
      'Accept-Language': 'vn',
      ...headers,
      Authorization: await getAuthorizationHeader(),
    },
    timeout: TIMEOUT,
  })

  return funcFetch(axiosInstance, endPoint, method, data, headers, { retry_time: 3 })
}

export default fetchApi
