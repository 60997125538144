import { lazy } from 'react'

// project import
import Loadable from 'components/Loadable'
import { MODULE } from 'constants/list-module'

const MenuListing = Loadable(lazy(() => import('pages/Menu/List')))
const MenuEditor = Loadable(lazy(() => import('pages/Menu/Editor/FormUpdate')))
const MenuAdd = Loadable(lazy(() => import('pages/Menu/Editor/FormAdd')))

const AppearanceRoutes = [
  {
    path: 'menus',
    element: <MenuListing />,
    code: MODULE.MENU,
  },
  {
    path: 'menu_editor/:id',
    element: <MenuEditor />,
    code: MODULE.MENU,
  },
  {
    path: 'menu_add',
    element: <MenuAdd />,
    code: MODULE.MENU,
  },
]

export default AppearanceRoutes
