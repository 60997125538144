import { lazy } from 'react'

// project import
import Loadable from 'components/Loadable'
import { MODULE } from 'constants/list-module'
import ProductTagListing from 'pages/ProductTag/Listing'
import { Navigate } from 'react-router-dom'

const ProductListing = Loadable(lazy(() => import('pages/Product/Listing')))
const ProductFormAdd = Loadable(lazy(() => import('pages/Product/FormAdd')))
const ProductFormUpdate = Loadable(lazy(() => import('pages/Product/FormUpdate')))
const ProductVariantUpdate = Loadable(lazy(() => import('pages/Product/ProductVariantUpdate')))
const ProductCategoryListing = Loadable(lazy(() => import('pages/ProductCategory/Listing')))
const ProductCategoryForm = Loadable(lazy(() => import('pages/ProductCategory/FormUpdate')))
const ProductSetupOption = Loadable(lazy(() => import('pages/ProductSetupOption')))
const SetupOptionColor = Loadable(lazy(() => import('pages/ProductSetupOption/SetupOptionColor')))
const SetupOptionSize = Loadable(lazy(() => import('pages/ProductSetupOption/SetupOptionSize')))

const ProductOptionListing = Loadable(lazy(() => import('pages/ProductOption/Listing')))
const ProductOptionForm = Loadable(lazy(() => import('pages/ProductOption/FormUpdate')))
const ProductTagUpdate = Loadable(lazy(() => import('pages/ProductTag/FormUpdate')))
const RaitingReview = Loadable(lazy(() => import('pages/Rating-Review/Listing')))
const RatingReviewDetail = Loadable(lazy(() => import('pages/Rating-Review/Detail')))
const ExportXMLListing = Loadable(lazy(() => import('pages/ExportXML/Listing')))
const ExportXMLHistoryListing = Loadable(lazy(() => import('pages/ExportXML/History')))
const MenuSizeGuideListing = Loadable(lazy(() => import('pages/SizeGuide/Listing')))
const SizeGuideListing = Loadable(lazy(() => import('pages/SizeGuide/Listing/SizeGuideList')))
const ParameterListing = Loadable(lazy(() => import('pages/SizeGuide/Listing/ParameterList')))
const CreateSizeGuide = Loadable(lazy(() => import('pages/SizeGuide/Create/CreateSizeGuide')))
const CreateParameter = Loadable(lazy(() => import('pages/SizeGuide/Create/CreateParameter')))
const EditSizeGuide = Loadable(lazy(() => import('pages/SizeGuide/Edit/EditSizeGuide')))
const EditParameter = Loadable(lazy(() => import('pages/SizeGuide/Edit/EditParameter')))
const ProductRoutes = [
  {
    path: 'products',
    element: <ProductListing />,
    code: MODULE.PRODUCT,
  },
  {
    path: 'products_new',
    element: <ProductFormAdd />,
    code: MODULE.PRODUCT,
  },
  {
    path: 'products/:id',
    element: <ProductFormUpdate />,
    code: MODULE.PRODUCT,
  },
  {
    path: 'products/:productId/variants',
    element: <ProductVariantUpdate />,
    code: MODULE.PRODUCT,
  },
  {
    path: 'products/:productId/variants/:variantId',
    element: <ProductVariantUpdate />,
    code: MODULE.PRODUCT,
  },
  {
    path: 'product/options',
    element: <ProductOptionListing />,
    code: MODULE.PRODUCT_OPTION,
  },
  {
    path: 'product/options/:id',
    element: <ProductOptionForm />,
    code: MODULE.PRODUCT_OPTION,
  },
  {
    path: 'product/setup-option',
    element: <ProductSetupOption />,
    code: MODULE.PRODUCT_ATTRIBUTE_GROUP,
    children: [
      {
        path: '',
        element: <Navigate to="color" replace />,
        code: MODULE.PRODUCT_ATTRIBUTE_GROUP,
      },
      {
        path: 'color',
        element: <SetupOptionColor />,
        code: MODULE.PRODUCT_ATTRIBUTE_GROUP,
      },
      {
        path: 'size',
        element: <SetupOptionSize />,
        code: MODULE.PRODUCT_ATTRIBUTE_GROUP,
      },
    ],
  },
  {
    path: 'product/categories',
    element: <ProductCategoryListing />,
    code: MODULE.PRODUCT_CATEGORY,
  },
  {
    path: 'product/categories/editor',
    element: <ProductCategoryForm />,
    code: MODULE.PRODUCT_CATEGORY,
  },
  {
    path: 'product/categories/editor/:id',
    element: <ProductCategoryForm />,
    code: MODULE.PRODUCT_CATEGORY,
  },
  {
    path: 'product/tags',
    element: <ProductTagListing />,
    code: MODULE.PRODUCT_TAG,
  },
  {
    path: 'product/tags/:id',
    element: <ProductTagUpdate />,
    code: MODULE.PRODUCT_TAG,
  },
  {
    path: 'size-guide',
    element: <MenuSizeGuideListing />,
    // code: MODULE.SIZE_GUIDE,
    children: [
      {
        path: '',
        element: <Navigate to="parameter" replace />,
        // code: MODULE.PRODUCT_ATTRIBUTE_GROUP,
      },
      {
        path: 'size-guide',
        element: <SizeGuideListing />,
        // code: MODULE.PRODUCT_ATTRIBUTE_GROUP,
      },
      {
        path: 'parameter',
        element: <ParameterListing />,
        // code: MODULE.PRODUCT_ATTRIBUTE_GROUP,
      },
    ],
  },
  {
    path: 'size-guide/create-size-guide',
    element: <CreateSizeGuide />,
    // code: MODULE.SIZE_GUIDE,
  },
  {
    path: 'size-guide/parameter/:id',
    element: <EditParameter />,
    // code: MODULE.SIZE_GUIDE,
  },
  {
    path: 'size-guide/size-guide/:id',
    element: <EditSizeGuide />,
    // code: MODULE.SIZE_GUIDE,
  },
  {
    path: 'size-guide/create-parameter',
    element: <CreateParameter />,
    // code: MODULE.SIZE_GUIDE,
  },

  {
    path: 'product/product-feed',
    element: <ExportXMLListing />,
    code: MODULE.EXPORT_XML,
  },
  {
    path: 'product/rating-review',
    element: <RaitingReview />,
    code: MODULE.PRODUCT_COMMENT,
  },
  {
    path: 'product/product-feed',
    element: <ExportXMLListing />,
    code: MODULE.EXPORT_XML,
  },
  {
    path: 'product/product-feed/listing',
    element: < ExportXMLHistoryListing />,
    code: MODULE.EXPORT_XML,
  },
  {
    path: 'product/rating-review/:id',
    element: <RatingReviewDetail />,
    code: MODULE.PRODUCT_COMMENT,
  },
]

export default ProductRoutes
