import { Chip, ListItemText } from '@mui/material'
import _ from 'lodash'
import { v4 as uuid } from 'uuid'

import { HTTP_STATUS } from '../constants/common'
import { layoutActions } from '../redux/slices/layoutSlice'
import { StoreStatusType } from 'constants/store-status-type'

export const uniqueId = () => uuid()

export const isSuccessRequest = (status) => {
  return [HTTP_STATUS.SUCCESS, HTTP_STATUS.CREATED_SUCCESS].includes(status)
}

export const createMessage = (msg) => {
  return layoutActions.showMessage({ ...msg, id: uuid(), createdAt: Date.now() })
}

export const parseURLQuery = (queryString = '') => {
  let strQuery = queryString?.trim()
  if (strQuery.indexOf('?') === 0) {
    strQuery = strQuery.substring(1)
  }

  let params = {}
  if (strQuery) {
    strQuery.split('&').forEach((query) => {
      const [key, value] = query.split('=')
      params[key] = decodeURI(value)
    })
  }

  return params
}

export const toBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })
}

export const htmlDecode = (input) => {
  var doc = new DOMParser().parseFromString(input, 'text/html')
  return doc.documentElement.textContent
}

export const createMarkup = (str) => {
  return { __html: str }
}

export const detectMob = () => {
  const toMatch = [/Android/i, /webOS/i, /iPhone/i, /iPad/i, /iPod/i, /BlackBerry/i, /Windows Phone/i]

  return toMatch.some((toMatchItem) => {
    return navigator.userAgent.match(toMatchItem)
  })
}

/**
 * Biến đổi 1 object thành chuỗi Query string
 *
 * {name: 'abc', age: 10} => ?name=abc&age=10
 *
 * @param {object} data
 * @returns
 */

export const transformObjToUrlQuery = (data) => {
  if (data && _.isObject(data)) {
    let query = []

    Object.keys(data).forEach((key) => {
      if (!_.isUndefined(data[key]) && !_.isNull(data[key])) {
        if (_.isArray(data[key])) {
          data[key].forEach((value) => {
            if (value !== '') {
              query.push(`${key}[]=${value}`)
            }
          })
        } else if (data[key] !== '') {
          query.push(`${key}=${data[key]}`)
        }
      }
    })

    return query.length > 0 ? '?' + query.join('&') : ''
  }

  return ''
}
/**
 * Thay thế 1 đoạn text cuối cùng bằng ký tự nào đó
 * @param {string} str
 * @param {number} num
 * @returns
 */
export const truncateString = (str, num, symbol = '...') => {
  if (str.length > num) {
    return str.slice(0, num) + symbol
  } else {
    return str
  }
}

/**
 * Loại bỏ tát cả các thể HTML
 * @param {string} html
 * @returns
 */
export function stripHtml(html) {
  let tmp = document.createElement('DIV')
  tmp.innerHTML = html
  return tmp.textContent || tmp.innerText || ''
}

/**
 * Sắp xếp lại mảng thành mảng dạng cây nhị phân
 * Demos
 * [{id: 1, parent_id: null}, {id: 2, parent_id: 1}, {id: 3, parent_id: 2}]
 * => [{id: 1, parent_id: null, children: [{id: 2, parent_id: 1, children: [{id: 3, parent_id: 2}]}]}]
 *
 * @param {Array} list
 * @param {String} fieldId
 * @param {String} fieldParent
 * @returns {Array}
 */
export function listToTree(list = [], fieldId = 'id', fieldParent = 'parent_id') {
  var map = {},
    node,
    roots = [],
    i

  for (i = 0; i < list.length; i += 1) {
    map[list[i][fieldId]] = i
    list[i].children = []
  }

  for (i = 0; i < list.length; i += 1) {
    node = list[i]
    if (node[fieldParent]) {
      if (map[node[fieldParent]] >= 0) {
        list[map[node[fieldParent]]].children.push(node)
      }
    } else {
      roots.push(node)
    }
  }

  return roots
}

/**
 * Sắp xếp lại mảng theo vị trí cha trước con sau
 * @param {Array} flatData
 * @returns {Array}
 */
export function flatSortByParent(flatData = []) {
  const resursive = (data, parent_id = null, depth = 0, resultList = []) => {
    data.forEach((item) => {
      item.depth = depth

      if (item.children.length > 0) {
        item.hasChildren = true
        resultList.push(item)

        resursive(item.children, item.id, item.depth + 1, resultList)
      } else {
        item.hasChildren = false
        resultList.push(item)
      }
    })

    return resultList
  }

  return resursive(listToTree(flatData))
}

export const mapStatusPost = (status) => {
  if (status === 1) {
    return <ListItemText primary={'Hoạt động'} sx={{ color: 'green', textAlign: 'center' }} />
  } else if (status === 0) {
    return <ListItemText primary={'Ngừng hoạt động'} sx={{ color: 'red', textAlign: 'center' }} />
  } else {
    return <ListItemText primary={''} />
  }
}

export function checkDecimalAndRound(number) {
  if (Number.isFinite(number)) {
    return Math.round(number * 100) / 100
  }
  return number
}

export const parseParams = (querystring) => {
  const params = new URLSearchParams(querystring)
  const obj = {}
  for (const key of params.keys()) {
    if (params.getAll(key).length > 1) {
      if (params.get(key) !== 'undefined') obj[key] = params.getAll(key)
    } else {
      if (params.get(key) !== 'undefined') obj[key] = params.get(key)
    }
  }

  return obj
}

export const removeUndefinedAttribute = (obj) => {
  const params = {};
  Object.keys(obj).forEach((key) => {
    if (obj[key] !== undefined && obj[key] !== null) {
      params[key] = obj[key];
    }
  });
  return params;
};

export function removeFalsyValues(obj) {
  for (let key in obj) {
    if (obj[key] === undefined || obj[key] === null || obj[key] === '') {
      delete obj[key]
    }
  }
  return obj
}

export function checkObjectFalsyValues(obj) {
  for (let key in obj) {
    if (obj[key] === undefined || obj[key] === null || obj[key] === '' || obj[key]?.length === 0) {
      return true
    }
  }
  return false
}

export function checkIsLocal() {
  return !!(window.location.hostname.indexOf('127.0.0.1') > -1 || window.location.hostname.indexOf('localhost') > -1)
}

export function errorMessage(status) {
  const code = {
    // CONTINUE = 100,
    // SWITCHING_PROTOCOLS = 101,
    // PROCESSING = 102,
    // OK = 200,
    // CREATED = 201,
    // ACCEPTED = 202,
    // NON_AUTHORITATIVE_INFORMATION = 203,
    // NO_CONTENT = 204,
    // RESET_CONTENT = 205,
    // PARTIAL_CONTENT = 206,
    // MULTI_STATUS = 207,
    // MULTIPLE_CHOICES = 300,
    // MOVED_PERMANENTLY = 301,
    // MOVED_TEMPORARILY = 302,
    // SEE_OTHER = 303,
    // NOT_MODIFIED = 304,
    // USE_PROXY = 305,
    // TEMPORARY_REDIRECT = 307,
    // PERMANENT_REDIRECT = 308,
    // BAD_REQUEST = 400,
    // UNAUTHORIZED = 401,
    // PAYMENT_REQUIRED = 402,
    // FORBIDDEN = 403,
    // NOT_FOUND = 404,
    // METHOD_NOT_ALLOWED = 405,
    // NOT_ACCEPTABLE = 406,
    // PROXY_AUTHENTICATION_REQUIRED = 407,
    // REQUEST_TIMEOUT = 408,
    // CONFLICT = 409,
    // GONE = 410,
    // LENGTH_REQUIRED = 411,
    // PRECONDITION_FAILED = 412,
    // REQUEST_TOO_LONG = 413,
    // REQUEST_URI_TOO_LONG = 414,
    // UNSUPPORTED_MEDIA_TYPE = 415,
    // REQUESTED_RANGE_NOT_SATISFIABLE = 416,
    // EXPECTATION_FAILED = 417,
    // IM_A_TEAPOT = 418,
    // INSUFFICIENT_SPACE_ON_RESOURCE = 419,
    // METHOD_FAILURE = 420,
    // MISDIRECTED_REQUEST = 421,
    // UNPROCESSABLE_ENTITY = 422,
    // LOCKED = 423,
    // FAILED_DEPENDENCY = 424,
    // PRECONDITION_REQUIRED = 428,
    // TOO_MANY_REQUESTS = 429,
    // REQUEST_HEADER_FIELDS_TOO_LARGE = 431,
    // UNAVAILABLE_FOR_LEGAL_REASONS = 451,
    // INTERNAL_SERVER_ERROR = 500,
    // NOT_IMPLEMENTED = 501,
    // BAD_GATEWAY = 502,
    // SERVICE_UNAVAILABLE = 503,
    // GATEWAY_TIMEOUT = 504,
    // HTTP_VERSION_NOT_SUPPORTED = 505,
    // INSUFFICIENT_STORAGE = 507,
    // NETWORK_AUTHENTICATION_REQUIRED = 511,
    // SOMETHING_WENT_WRONG_PLEASE_TRY_AGAIN_LATER = 1000,
    // UPDATE_FAIL = 1001,
    // FAILED_TO_UPDATE_POST = 1002,
    // FILE_TOO_BIG = 1003,
    // ROLE_NOT_FOUND = 1004,
    // USER_NOT_FOUND = 1005,
    // USER_IS_EXISTED = 1006,
    // USER_IS_NOT_VERIFIED = 1007,
    // UNAUTHORIZE = 1008,
    // POST_IS_EXISTED = 1009,
    // ATTACHMENT_NOT_FOUND = 1010,
    // THIS_ACTION_FOR_ADMIN = 1011,
    // AUTH_ADMIN_OR_COORDINATOR = 1012,
    // FILE_CANNOT_EMPTY = 1013,
    // TAG_NOT_FOUND = 1014,
    // TAG_IS_EXISTED = 1015,
    // FAILED_TO_UPDATE_TAG = 1016,
    // CATEGORY_NOT_FOUND = 1017,
    // CATEGORY_IS_EXISTED = 1018,
    // FAILED_TO_UPDATE_CATEGORY = 1019,
    // POST_NOT_FOUND = 1020,
    // DUPLICATE_PATH = 1021,
    // DUPLICATE_OPTION = 1022,
    // DUPLICATE_OPTION_VALUE = 1023,
    // DUPLICATE_TAG = 1024,
    // PRODUCT_NOT_FOUND = 1025,
    // MENU_NOT_FOUND = 1026,
    // CONFIG_CODE_NOT_FOUND = 1027,
    // POPUP_DUPLICATE_TIME = 1028,
    // INVALID_STORE ="INVALID_STORE",
    // INVALID_OBJECTID='INVALID_OBJECTID',
    // REGION_NOT_FOUND="REGION_NOT_FOUND",
    // PROMOTION_NEWS_NOT_FOUND='PROMOTION_NEWS_NOT_FOUND',
    // NOTIFICATION_NOT_FOUND='NOTIFICATION_NOT_FOUND',
    FLASHSALE_DUPLICATE_TIME: 'Các Flashsale được tạo không thể trùng khoảng thời gian!',
    1021: 'Đường dẫn đã tồn tại',
    'Duplicate path': 'Đường dẫn đã tồn tại',
  }

  return code[status] ? code[status] : 'Có lỗi xảy ra'
}

export const compareVariant = (a, b) => {
  return a?.sku?.slice(-2) - b?.sku?.slice(-2)
}
