import { DeleteOutlined, UploadFileRounded } from '@mui/icons-material'
import { Box, Button, styled as MUIStyled, Typography } from '@mui/material'
import React, { ChangeEvent } from 'react'
import { isValidUrlImage } from 'utils/validate'

interface Props {
  className?: string
  image?: object | null
  caption?: string
  onChange(data: any): void
  multiple?: boolean
  disabled?: boolean
}

const formatImage = (image: any) => {
  if (!image) return null

  if (image instanceof File) {
    return URL.createObjectURL(image)
  } else {
    return isValidUrlImage(image?.link || image?.thumbnail)
  }
}

const UploadSingleImage = MUIStyled(
  ({ className, image, caption, onChange, multiple, disabled }: Props): JSX.Element => {
    const [dragActive, setDragActive] = React.useState(false)
    const onUpload = (files: FileList) => {
      if (files.length === 0) {
        return
      }
      onChange(files[0])
    }

    // handle drag events
    const handleDrag = function (e: any) {
      e.preventDefault()
      e.stopPropagation()
      if (e.type === 'dragenter' || e.type === 'dragover') {
        setDragActive(true)
      } else if (e.type === 'dragleave') {
        setDragActive(false)
      }
    }

    // triggers when file is dropped
    const handleDrop = function (e: any) {
      e.preventDefault()
      e.stopPropagation()
      setDragActive(false)
      if (e.dataTransfer.files && e.dataTransfer.files[0]) {
        const file_type = e.dataTransfer.files[0]['type']
        const validImageTypes = ['image/gif', 'image/jpeg', 'image/png']
        if (validImageTypes.includes(file_type)) {
          onChange(e.dataTransfer.files[0])
        } else {
        }
      }
    }
    return (
      <Box
        onDragEnter={handleDrag}
        onDragLeave={handleDrag}
        onDragOver={handleDrag}
        onDrop={handleDrop}
        className={[className].join(' ')}
        sx={{ backgroundColor: dragActive ? 'rgba(25, 118, 210, 0.12)' : '#fff' }}
      >
        {image ? (
          <>
            <img src={formatImage(image)} className="image" alt="" />
            {!disabled && <DeleteOutlined color="error" className="delete_btn" onClick={() => onChange(null)} />}
          </>
        ) : (
          <>
            <Box
              className="upload_icon_div"
              sx={{
                backgroundColor: `rgba(25, 118, 210, ${dragActive ? 0.3 : 0.12})`,
              }}
            >
              <UploadFileRounded color="primary" />
            </Box>
            <Typography variant="h5" className="title">
              <Button component="label" disabled={disabled}>
                <Typography className="upload_title">Click to upload or drag and drop</Typography>
                <input
                  multiple={multiple ? true : false}
                  hidden
                  accept="image/*"
                  type="file"
                  onChange={(e: ChangeEvent) => {
                    const { files } = e.target as HTMLInputElement
                    if (files) onUpload(files)
                  }}
                />
              </Button>
            </Typography>
            {caption && (
              <Typography variant="h6" className="caption">
                {caption}
              </Typography>
            )}
          </>
        )}
      </Box>
    )
  }
)(({ theme }) => {
  return {
    // minWidth: 300,
    height: 180,
    border: '1px dashed rgba(0, 0, 0, 0.88)',
    borderRadius: 6,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    '& .image': {
      height: '90%',
      maxWidth: '100%',
      objectFit: 'contain',
    },
    '& .delete_btn': {
      position: 'absolute',
      bottom: theme.spacing(1),
      right: theme.spacing(1),
      '&:hover': {
        cursor: 'pointer',
      },
    },
    '& .upload_icon_div': {
      width: 40,
      height: 40,
      borderRadius: 40,
      justifyContent: 'center',
      display: 'flex',
      alignItems: 'center',
    },
    '& .title': {
      marginTop: theme.spacing(1),
      '& .upload_title': {
        // textDecorationLine: 'underline',
        // color: 'rgba(25,118,210)',
        textAlign: 'center',
      },
    },
    '& .caption': {
      marginTop: theme.spacing(0.5),
    },
  }
})

export default UploadSingleImage
