import React, { CSSProperties } from 'react'
import { Typography } from '@mui/material'
import { Box } from '@mui/system'

interface IProps {
  lable: string
  required?: boolean
  style?: CSSProperties
  lableStyle?: CSSProperties
  bold?: boolean
  bottom?: string | number
}

const UiFieldLable: React.FC<IProps> = ({ bottom, lable, style, lableStyle = {}, required = false, bold = true }) => {
  return (
    <Box
      style={{
        ...style,
        marginBottom: bottom ? bottom : 'auto',
      }}
    >
      <Typography
        component="span"
        style={{
          ...lableStyle,
          fontWeight: bold ? '600' : 'normal',
        }}
      >
        {lable}{' '}
        {required ? (
          <Typography component="span" color="red">
            *
          </Typography>
        ) : null}
      </Typography>
    </Box>
  )
}

export default UiFieldLable
