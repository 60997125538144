import { lazy } from 'react'

// project import
import Loadable from 'components/Loadable'
import { MODULE } from 'constants/list-module'

const StaticPageListing = Loadable(lazy(() => import('pages/StaticPageManagement/Listing')))
const CreateStaticPage = Loadable(lazy(() => import('pages/StaticPageManagement/Create')))
const StaticPageDetail = Loadable(lazy(() => import('pages/StaticPageManagement/Detail')))
const StaticPageRoutes = [
  {
    path: 'static-pages',
    element: <StaticPageListing />,
    code: MODULE.STATIC_PAGE,
  },
  {
    path: 'static-pages/create',
    element: <CreateStaticPage />,
    code: MODULE.STATIC_PAGE,
  },
  {
    path: 'static-pages/:id',
    element: <StaticPageDetail />,
    code: MODULE.STATIC_PAGE,
  },
]

export default StaticPageRoutes
