import StoreIcon from '@mui/icons-material/Store'
import { MODULE } from 'constants/list-module'

const Store = {
  id: 'store',
  icon: <StoreIcon />,
  title: 'Cửa hàng',
  url: '/stores',
  role: ['*'],
  code: MODULE.STORE,
  type: 'single',
}

export default Store
