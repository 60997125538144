// import { useRouter } from 'next/router'
import PropTypes from 'prop-types'
import { Box, Button, ListItem } from '@mui/material'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { activeItem } from '../../../../../redux/slices/menuSlice'
import { MenuItem } from 'models/common'
import { RootState } from 'redux/store'

interface Props {
  item: MenuItem
}

const NavItem = ({ item }: Props) => {
  const dispatch = useDispatch()
  const menu = useSelector((state: RootState) => state.menu)
  const { drawerOpen, openItem } = menu

  const active = false

  const itemHandler = (id: string) => {
    dispatch(activeItem({ openItem: [id] }))
  }

  const isSelected = openItem.findIndex((id) => id === item.id) > -1

  // active menu item on page load
  useEffect(() => {
    const currentIndex = document.location.pathname
      .toString()
      .split('/')
      .findIndex((id) => id === item.id)

    if (currentIndex > -1) {
      dispatch(activeItem({ openItem: [item.id] }))
    }
  }, [dispatch, item.id])

  return (
    <ListItem
      disableGutters
      sx={{
        display: 'flex',
        py: 0,
        // mb: 1,
      }}
    >
      <Button
        component={Link}
        to={item.url || ''}
        startIcon={item.icon}
        disableRipple
        onClick={() => itemHandler(item.id)}
        sx={{
          backgroundColor: isSelected ? 'rgba(255,255,255, 0.08)' : '',
          borderRadius: 0,
          color: active ? 'secondary.main' : 'rgb(238, 238, 238)',
          fontWeight: active ? 'fontWeightBold' : '',
          justifyContent: 'flex-start',
          px: 3.5,
          width: '100%',
          '& .MuiButton-startIcon': {
            color: active ? 'secondary.main' : 'neutral.400',
          },
          '&:hover': {
            backgroundColor: 'rgba(255,255,255, 0.08)',
          },
        }}
      >
        <Box sx={{ flexGrow: 1 }}>
          {item.title} {item?.numberNoti || null}
        </Box>
      </Button>
    </ListItem>
  )
}

export default NavItem
