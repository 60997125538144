import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
  styled,
} from '@mui/material'
import PropTypes from 'prop-types'

// 3th package
import { Formik } from 'formik'
import * as Yup from 'yup'

// Services
import Brightness1Icon from '@mui/icons-material/Brightness1'
import { LoadingButton } from '@mui/lab'
import { Field } from 'components'
import { MESSAGE_TYPES } from 'constants/common'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import * as commonService from 'services/common.service'
import * as productService from 'services/product.service'
import { createMessage } from 'utils/common'
import { isCreate, isUpdate } from 'utils/permissions/action-permission'
TagForm.propTypes = {
  mode: PropTypes.string,
  value: PropTypes.object,
  onCreated: PropTypes.func,
  onEdited: PropTypes.func,
}

TagForm.defaultProps = {
  mode: 'add',
  value: {},
  onCreated: () => { },
  onEdited: () => { },
}

const StyledPage = styled(Box)(() => {
  return {
    '& .MuiSelect-select': {
      display: 'flex',
      alignItems: 'center',
      gap: '10px',
      minWidth: '120px',
    },
    '& .container-button': {
      marginTop: '80px',
      textAlign: 'center',
    },
  }
})

const DEFAULT_BG_COLOR = '#B4AB3B'

const checkOrder = (color) => {
  if (color === '#B4AB3B') {
    return 1
  } else if (color === '#007A8B') {
    return 2
  } else {
    return null
  }
}
const listRegions = [
  { name: 'Miền Bắc', value: 'mien-bac' },
  { name: 'Miền Trung', value: 'mien-trung' },
  { name: 'Miền Nam', value: 'mien-nam' },
  { name: 'Tất cả khu vực', value: 'all' },
]

function TagForm({ onCreated, onEdited, mode, value, setOpenDrawer, actions }) {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const handleSave = async (values, { setSubmitting, resetForm }) => {
    setSubmitting(true)
    let resUploadImage = null
    const checkUpdateImg = values.image ? (values.image.id ? true : false) : false
    if (!checkUpdateImg && values.image) {
      resUploadImage = await commonService.createAttachment(values.image)
    }

    try {
      if (mode === 'add') {
        const res = await productService.createTag({
          ...values,
          image: resUploadImage?.id,
          regions: values?.regions?.find(i => i.value == 'all') ? [] : values?.regions?.map(item => item.value),
          order: values?.order < 0 ? values?.order : await checkOrder(values.background_color),
        })
        if (res.success === false) {
          throw new Error(res)
        }
        dispatch(createMessage({ type: MESSAGE_TYPES.SUCCESS, content: 'Thêm thẻ thành công' }))
        onCreated()
      }

      if (mode === 'edit') {
        const data = {
          value: values?.value,
          label: values?.label,
          image: checkUpdateImg ? values.image?.id : resUploadImage?.id,
          status: values?.status,
          background_color: values?.background_color,
          order: values?.order < 0 ? values?.order : checkOrder(values.background_color),
          regions: values?.regions?.find(i => i.value == 'all') ? [] : values?.regions?.map(item => item.value),
          is_active_utm: values.is_active_utm,
          utm_campaign: values.utm_campaign
        }
        const res = await productService.updateTag(value.id, data)
        dispatch(createMessage({ type: MESSAGE_TYPES.SUCCESS, content: 'Sửa thẻ thành công' }))
        onEdited(value.id, values)
      }

      setSubmitting(false)
    } catch (error) {
      setSubmitting(false)
      if (error?.message === 'Duplicate tag') {
        dispatch(createMessage({ type: MESSAGE_TYPES.ERROR, content: 'Tên thẻ đã tồn tại !' }))
      } else {
        if (mode === 'add') {
          dispatch(createMessage({ type: MESSAGE_TYPES.ERROR, content: 'Thêm thẻ không thành công' }))
        }

        if (mode === 'edit') {
          dispatch(createMessage({ type: MESSAGE_TYPES.ERROR, content: 'Sửa thẻ không thành công' }))
        }
      }
    }
  }

  return (
    <StyledPage>
      <Container maxWidth="md" sx={{ p: 0 }}>
        <Formik
          enableReinitialize={true}
          initialValues={{
            ...value,
            status: value?.status,
            background_color: value?.background_color || DEFAULT_BG_COLOR,
            regions: (value?.regions?.length > 0 && listRegions.filter(obj => value?.regions?.includes(obj.value))
              .map(obj => ({ value: obj.value, name: obj.name }))) || [listRegions.find(i => i.value == 'all')]
          }}
          validationSchema={Yup.object().shape({
            value: Yup.string().when('order', {
              is: (val) => val >= 0,
              then: Yup.string()
                .trim()
                .required('Tên không được để trống')
                // .min(5, 'Tên phải có ít nhất 5 ký tự')
                .max(100, 'Tên có tối đa 100 ký tự'),
              otherwise: Yup.string().notRequired(),
            }),
          })}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            handleSave(values, { setSubmitting, resetForm })
          }}
        >
          {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => {
            return (
              <form noValidate onSubmit={handleSubmit}>
                <Box>
                  <FormControl variant="standard" fullWidth margin="normal">
                    <Typography sx={{ mb: 1 }}>
                      Tên thẻ
                      <Typography variant="span" color="error">
                        (*)
                      </Typography>
                    </Typography>
                    <TextField
                      variant="outlined"
                      fullWidth
                      name="value"
                      value={values.value}
                      placeholder="Nhập tên"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={Boolean(touched.value && errors.value)}
                      disabled={Number(values?.order || 0) < 0}
                    />
                    {errors.value && <FormHelperText error>{errors.value}</FormHelperText>}
                  </FormControl>

                  <FormControl variant="standard" fullWidth margin="normal">
                    <Typography sx={{ mb: 1 }}>Mô tả </Typography>
                    <TextField
                      name="label"
                      multiline
                      rows="4"
                      disabled={Number(values?.order || 0) < 0}
                      placeholder="Nhập mô tả"
                      value={values.label}
                      onChange={handleChange}
                      error={Boolean(touched.label && errors.label)}
                    />

                    {errors.label && touched.label && <FormHelperText error>{errors.label}</FormHelperText>}
                  </FormControl>
                  <FormControl variant="standard" fullWidth margin="normal">
                    <Typography sx={{ mb: 1 }}>Hình ảnh</Typography>
                    <Field
                      disabled={Number(values?.order || 0) < 0}
                      value={values.image || ''}
                      type="image"
                      onChange={(image) => {
                        setFieldValue('image', image)
                      }}
                      marginBottom={8}
                    />
                  </FormControl>
                  <Box sx={{ mt: '8px' }}>
                    <Field
                      value={values.utm_campaign}
                      onChange={(value) => setFieldValue('utm_campaign', value)}
                      label="UTM Campaign"
                      placeholder="Nhập utm campaign"
                    />
                    <FormControl fullWidth sx={{ mb: 2 }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="is_active_utm"
                            checked={!!values.is_active_utm}
                            onChange={(e, value) => setFieldValue('is_active_utm', value)}
                          />
                        }
                        label="utm campaign"
                      />
                    </FormControl>
                  </Box>
                  <FormControl variant="standard" fullWidth margin="normal">
                    <Typography sx={{ mb: 1 }}>Khu vực</Typography>
                    <Autocomplete
                      multiple
                      value={values?.regions || []}
                      fullWidth
                      size="small"
                      disablePortal
                      options={listRegions}
                      getOptionLabel={(option) => option.name}
                      isOptionEqualToValue={(option, value) => {
                        return option?.value === value?.value
                      }}
                      renderInput={(params) => {
                        return (
                          <TextField
                            {...params}
                            placeholder="Chọn khu vực"
                          />
                        )
                      }}
                      onChange={(e, value) => {
                        if (value.find(i => i.value == 'all')) {
                          setFieldValue('regions', [value.find(i => i.value == 'all')])
                        }
                        else setFieldValue('regions', value)
                      }}
                      sx={{
                        backgroundColor: 'white',
                      }}
                    />
                    {/* <Select
                      multiple
                      value={values?.regions || []}
                      onChange={(e) => setFieldValue('regions', e.target.value)}
                      fullWidth
                      size="small"
                    // defaultValue={'all'}
                    >
                      <MenuItem value={'all'}>Tất cả khu vực</MenuItem>
                      <MenuItem value={'mien-bac'}>Miền Bắc</MenuItem>
                      <MenuItem value={'mien-trung'}>Miền Trung</MenuItem>
                      <MenuItem value={'mien-nam'}>Miền Nam</MenuItem>
                    </Select> */}
                  </FormControl>
                  <Grid container>
                    <Grid item md={6} xs={12} sm={12}>
                      <FormControl>
                        <Typography sx={{ mb: 1 }}>Màu sắc</Typography>
                        <Select
                          value={values?.background_color}
                          onChange={(e) => setFieldValue('background_color', e.target.value)}
                          fullWidth
                          disabled={Number(values?.order || 0) < 0}
                          size="small"
                          placeholder="Chọn màu sắc hiển thị"
                        >
                          <MenuItem value={'#B4AB3B'} sx={{ display: 'flex ', alignItems: 'center', gap: '10px ' }}>
                            <Brightness1Icon sx={{ color: '#B4AB3B' }} />
                            <Typography>Promotion</Typography>
                          </MenuItem>
                          <MenuItem value={'#007A8B'} sx={{ display: 'flex ', alignItems: 'center', gap: '10px ' }}>
                            <Brightness1Icon sx={{ color: '#007A8B' }} />
                            <Typography>Extra</Typography>
                          </MenuItem>
                          {Number(values?.order || 0) < 0 && (
                            <MenuItem value={'#ED4A36'} sx={{ display: 'flex ', alignItems: 'center', gap: '10px ' }}>
                              <Brightness1Icon sx={{ color: '#ED4A36' }} />
                              <Typography>#ED4A36</Typography>
                            </MenuItem>
                          )}
                          {Number(values?.order || 0) < 0 && (
                            <MenuItem value={'#BD131F'} sx={{ display: 'flex ', alignItems: 'center', gap: '10px ' }}>
                              <Brightness1Icon sx={{ color: '#BD131F' }} />
                              <Typography>#BD131F</Typography>
                            </MenuItem>
                          )}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item md={6} xs={12} sm={12}>
                      <FormControl variant="standard" fullWidth>
                        <Typography sx={{ mb: 1 }}>Trạng thái</Typography>
                        <Select
                          value={values?.status}
                          onChange={(e) => setFieldValue('status', e.target.value)}
                          fullWidth
                          size="small"
                          defaultValue={true}
                        >
                          <MenuItem value={true}>Hiển thị</MenuItem>
                          <MenuItem value={false}>Ngưng hiển thị</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Box>

                <Box className="container-button">
                  <LoadingButton
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={mode === 'add' ? !isCreate(actions) : !isUpdate(actions)}
                    disableElevation
                    loading={isSubmitting}
                    style={{ marginRight: '10px' }}
                  >
                    Lưu
                  </LoadingButton>
                  <Button
                    variant="contained"
                    color="error"
                    onClick={() => {
                      navigate('/product/tags')
                      setOpenDrawer(false)
                    }}
                  >
                    Hủy
                  </Button>
                </Box>
              </form>
            )
          }}
        </Formik>
      </Container>
    </StyledPage>
  )
}

export default TagForm
