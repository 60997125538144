import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  filter: {
    created_by: {},
    category: {},
  },
}

const filterSlice = createSlice({
  initialState,
  name: 'post',
  reducers: {
    setFilterCreatedBy: (state, action) => {
      const { newFilter } = action.payload
      return { ...state, filter: { ...state.post, created_by: newFilter } }
    },
    setFilterCategory: (state, action) => {
      const { newFilter } = action.payload
      return { ...state, filter: { ...state.post, category: newFilter } }
    },
    deleteFilterAction: (state, action) => {
      const { nameFilter, deleteFilter } = action.payload
      const newFilter = { ...state.post }
      delete newFilter[newFilter[nameFilter][deleteFilter]]
      return { ...state, filter: newFilter }
    },
  },
})

export const { actions: postActions, reducer: postReducer } = filterSlice
export const postSelector = (state) => state.post
