import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount'
import { MODULE } from 'constants/list-module'

const Customer = {
  id: 'customer',
  icon: <SupervisorAccountIcon />,
  title: 'Khách hàng',
  url: '/customers',
  role: ['*'],
  code: MODULE.CUSTOMER,
  type: 'single',
}

export default Customer
