import { call, put, takeLatest } from 'redux-saga/effects'
import * as mediasService from '../../services/medias.services'
import { mediasActions } from '../slices/mediasSlice'

/**
 * Get user info by token
 */
export function* getAttachments(payload: any): Generator<any, any, unknown> {
  try {
    const res = yield call(mediasService.getAttachments, payload.payload)
    yield put(mediasActions.getMediasSuccess(res))

    // if (payload && payload.onSuccess) {
    //   payload.onSuccess(res)
    // }
  } catch (error) {
    yield put(mediasActions.getMediasError(error))
    // if (payload && payload.onError) {
    //   payload.onError(error)
    // }
  }
}

export default function* mediasSaga() {
  yield takeLatest(mediasActions.getMedias, getAttachments)
}
