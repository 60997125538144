// icons
import { DesktopOutlined } from '@ant-design/icons'
import { MenuOutlined } from '@mui/icons-material'
import { BIGMODULE, MODULE } from 'constants/list-module'

const appearance = {
  id: 'appearance',
  icon: <DesktopOutlined />,
  title: 'Giao diện',
  role: ['*'],
  code: BIGMODULE.DISPLAY,
  type: 'group',
  children: [
    {
      id: 'menu',
      url: '/menus',
      icon: <MenuOutlined />,
      title: 'Menu',
      code: MODULE.MENU,
      role: ['*'],
    },

    {
      id: 'appearance-setting',
      url: '/appearance-setting',
      icon: <MenuOutlined />,
      title: 'Cấu hình',
      code: MODULE.CONFIGS,
      role: ['*'],
    },
  ],
}

export default appearance
