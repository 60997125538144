import { cloneElement } from 'react'
import { render, unmountComponentAtNode } from 'react-dom'

import MessageBox from './MessageBox'
import ConfirmBox from './ConfirmBox'
// import WaitingBox from './WaitingBox'
// import ModalBox from './ModalBox'
import { isFunction } from 'lodash'

function renderDialog(component, onOpen, onClose) {
  if (typeof document !== 'undefined') {
    const dialogEl = document.createElement('div')
    const dialogConfig = {
      onClose() {
        unmountComponentAtNode(dialogEl)

        if (isFunction(onClose)) {
          onClose()
        }
      },
      onOpen(dialog) {
        if (isFunction(onOpen)) {
          onOpen(dialog)
        }
      },
    }

    document.body.appendChild(dialogEl)

    render(cloneElement(component, dialogConfig), dialogEl)
  }
}

export { renderDialog, ConfirmBox, MessageBox }
