import PermMediaIcon from '@mui/icons-material/PermMedia'
import { MODULE } from 'constants/list-module'

const MediasManagement = {
  id: 'medias-management',
  icon: <PermMediaIcon />,
  title: 'Quản lí hình ảnh',
  url: '/medias-management',
  role: ['*'],
  code: MODULE.MEDIAS_MANAGEMENT,
  type: 'single',
}

export default MediasManagement
