// import { useRouter } from 'next/router'
import PropTypes from 'prop-types'
import { Box, Button, ListItem } from '@mui/material'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import { activeItem } from '../../../../../redux/slices/menuSlice'
import { MenuItem } from 'models/common'
import { RootState } from 'redux/store'

interface Props {
  item: MenuItem,
  open: boolean,
  onToggle: Function
}

const CollapseHeader = ({ item, open, onToggle }: Props) => {
  const dispatch = useDispatch()
  const menu = useSelector((state: RootState) => state.menu)
  const { drawerOpen, openItem } = menu

  const active = false

  // const itemHandler = (id) => {
  //   dispatch(activeItem({ openItem: [id] }))
  // }

  const isSelected = openItem.findIndex((id) => id === item.id) > -1

  // Toggle collapse
  const icon = open ? <ExpandLess /> : <ExpandMore />

  // active menu item on page load
  useEffect(() => {
    const currentIndex = document.location.pathname
      .toString()
      .split('/')
      .findIndex((id) => id === item.id)

    if (currentIndex > -1) {
      dispatch(activeItem({ openItem: [item.id] }))
    }
  }, [dispatch, item.id])

  return (
    <ListItem
      disableGutters
      sx={{
        display: 'flex',
        py: 0,
      }}
    >
      <Button
        component={Box}
        startIcon={item.icon}
        disableRipple
        onClick={() => onToggle(!open)}
        sx={{
          backgroundColor: isSelected ? 'rgba(255,255,255, 0.08)' : '',
          borderRadius: 0,
          color: active ? 'secondary.main' : 'rgb(238, 238, 238)',
          fontWeight: active ? 'fontWeightBold' : '',
          justifyContent: 'flex-start',
          pl: 3.5,
          width: '100%',
          '& .MuiButton-startIcon': {
            color: active ? 'secondary.main' : 'neutral.400',
          },
          '&:hover': {
            backgroundColor: 'rgba(255,255,255, 0.08)',
          },
        }}
      >
        <Box sx={{ flexGrow: 1 }}>{item.title}</Box>
        {icon}
      </Button>
    </ListItem>
  )
}

CollapseHeader.propTypes = {
  href: PropTypes.string,
  icon: PropTypes.node,
  title: PropTypes.string,
}

export default CollapseHeader
