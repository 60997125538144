// material-ui
import { useTheme } from '@mui/material/styles'
import { IconButton, Stack, Typography } from '@mui/material'

// project import
import DrawerHeaderStyled from './DrawerHeaderStyled'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import { appTitle, domainForlifeHome } from 'config'

// ==============================|| DRAWER HEADER ||============================== //

interface Props {
  open: boolean
}

const DrawerHeader = ({ open }: Props) => {
  const theme = useTheme()

  return (
    <DrawerHeaderStyled theme={theme} open={open}>
      <a href={domainForlifeHome} style={{ textDecoration: 'none' }}>
        <Stack direction="row" spacing={1} alignItems="center">
          <Typography variant="h5">
            <Stack direction="row" spacing={1} alignItems="center">
              <IconButton>
                <KeyboardArrowLeftIcon style={{ color: '#fff', fontSize: '1.5rem' }} />
              </IconButton>{' '}
              <Typography>Forlife Home</Typography>
            </Stack>
          </Typography>
        </Stack>
      </a>
    </DrawerHeaderStyled>
  )
}

export default DrawerHeader
