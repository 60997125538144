import WebIcon from '@mui/icons-material/Web'
import { MODULE } from 'constants/list-module'

const ckcPage = {
  id: 'ckc-page',
  title: 'Quản lý CT CKC',
  icon: <WebIcon />,
  url: '/ckc_pages',
  code: MODULE.CKCPAGE,
  role: ['*'],
  type: 'single',
}

export default ckcPage
