// material-ui
import { Box, IconButton, Link, Typography, useMediaQuery } from '@mui/material'
import { GithubOutlined } from '@ant-design/icons'

// project import
import Search from './Search'
import Profile from './Profile'
import Notification from './Notification'
import MobileSection from './MobileSection'

// ==============================|| HEADER - CONTENT ||============================== //

const HeaderContent = () => {
  const matchesXs = useMediaQuery((theme) => theme.breakpoints.down('md'))

  return (
    <>
      {/* {!matchesXs && <Search />} */}
      <Box sx={{ width: '100%' }}>
        <Typography sx={{ ml: 1 }}>TOKYOLIFE CMS</Typography>
      </Box>
      {/* <Notification /> */}
      <Profile />
      {/* {!matchesXs && <Profile />} */}
      {/* {matchesXs && <MobileSection />} */}
    </>
  )
}

export default HeaderContent
