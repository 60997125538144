import { takeLatest, call, put, take } from 'redux-saga/effects'
import { authActions } from '../slices/authSlice'
import * as authService from '../../services/auth.service'
import LocalStorage from '../../utils/localStorage'

//Models
import { Meta } from '../../models/common'
import Cookies from 'utils/cookies'
import { domainForlifeHome } from 'config'

const login = function* ({ payload }: { payload: { username: string; password: string; meta?: Meta } }): any {
  const { meta, ...data } = payload
  try {
    const res = yield call(authService.login, data)

    Cookies.setToken(res.access_token)
    Cookies.setRefreshToken(res.refresh_token)
    yield put(authActions.loginSuccess(data))

    if (meta && meta.onSuccess) {
      meta.onSuccess(res)
    }
  } catch (error) {
    yield put(authActions.loginError(error))
    if (meta && meta?.onError) {
      meta?.onError(error)
    }
  }
}

const logout = function* ({ payload }: { payload: { onSuccess?: Function; onError?: Function } }) {
  try {
    yield call(authService.logout)
    // yield call(LocalStorage.remove('token'))
    LocalStorage.remove('token')
    Cookies.remove('token')
    Cookies.remove('refresh_token')
    if (window.location.href.indexOf('/login') === -1) window.location.href = domainForlifeHome

    if (payload?.onSuccess) payload.onSuccess()
  } catch (error) {
    if (payload?.onError) payload.onError()
  }
}

export default function* authSaga() {
  yield takeLatest(authActions.login, login)
  yield takeLatest(authActions.logout, logout)
}
