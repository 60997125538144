import { MODULE } from 'constants/list-module'
import AddHomeIcon from '@mui/icons-material/AddHome'

const Depot = {
  id: 'depot',
  icon: <AddHomeIcon />,
  title: 'Kho lấy tồn',
  url: '/depot',
  role: ['*'],
  code: MODULE.DEPOT,
  type: 'single',
}

export default Depot
