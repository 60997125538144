import WysiwygIcon from '@mui/icons-material/Wysiwyg'
import { MODULE } from 'constants/list-module'

const StaticPage = {
  id: 'static-page',
  icon: <WysiwygIcon />,
  title: 'Quản lí trang tĩnh',
  url: '/static-pages',
  role: ['*'],
  code: MODULE.STATIC_PAGE,
  type: 'single',
}

export default StaticPage
