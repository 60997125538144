import * as React from 'react'

import { Box, Button, Grid, IconButton, Paper, Stack, TablePagination, Tooltip, Typography } from '@mui/material'

// Icons
import PlusOutlined from '@ant-design/icons/PlusOutlined'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import DriveFileRenameOutlineOutlinedIcon from '@mui/icons-material/DriveFileRenameOutlineOutlined'

import { Link, useLocation, useSearchParams } from 'react-router-dom'
// import Swal from 'sweetalert2'
// import { toast } from 'react-toastify'
import _ from 'lodash'

// Components
import MainDrawer from 'components/MainDrawer'
import TagForm from '../components/TagForm'
import ListingTableToolbar from './ListingTableToolbar'

// Services
import MainContent from 'components/MainContent'
import { ConfirmBox, renderDialog } from 'components/ui/UiDialog'
import UiTable from 'components/ui/UiTable'
import { MESSAGE_TYPES } from 'constants/common'
import { useDispatch } from 'react-redux'
import * as productService from 'services/product.service'
import { createMessage, parseParams, removeUndefinedAttribute } from 'utils/common'
import { isCreate, isDelete, isUpdate } from 'utils/permissions/action-permission'

export default function ProductTagListing({ actions = [] }) {
  const [isLoading, setIsLoading] = React.useState(false)
  const [data, setData] = React.useState([])
  const [pagination, setPagination] = React.useState({})
  const location = useLocation()
  const [filter, setFilter] = React.useState({ search: '', page: 1, per_page: 10, ...parseParams(location.search) })
  const [openDrawer, setOpenDrawer] = React.useState(false)
  const [openDrawerMode, setOpenDrawerMode] = React.useState('add')
  const [openDrawerData, setOpenDrawerData] = React.useState({})
  const [searchParams, setSearchParams] = useSearchParams()

  React.useEffect(() => {
    setSearchParams(removeUndefinedAttribute(filter))
  }, [filter])

  const dispatch = useDispatch()

  const fetchData = async () => {
    setIsLoading(true)

    try {
      const data = await productService.getTags(filter)
      setData(data.data)
      setPagination(data.pagination)
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
    }
  }

  const handleChangeFilter = (newFilter) => {
    setFilter({ ...filter, ...newFilter })
  }

  const handleChangePage = (event, newPage) => {
    setFilter({ ...filter, page: newPage + 1 })
  }

  const handleChangeRowsPerPage = (event) => {
    setFilter({ ...filter, page: 1, per_page: parseInt(event.target.value, 10) })
  }

  const handleDeleteTag = (id) => {
    return renderDialog(
      <ConfirmBox
        onConfirm={async () => {
          setIsLoading(true)
          try {
            await productService.deleteTag(id)
            dispatch(createMessage({ type: MESSAGE_TYPES.SUCCESS, content: 'Xóa thẻ thành công' }))
            setIsLoading(false)
            setFilter({ ...filter, page: 1 })
            fetchData()
          } catch (error) {
            dispatch(createMessage({ type: MESSAGE_TYPES.ERROR, content: 'Xóa thẻ không thành công' }))
            setIsLoading(false)
          }
        }}
      >
        Bạn có muốn tiếp tục xóa thẻ?
      </ConfirmBox>
    )
  }

  const handleSearch = _.debounce((data) => {
    setFilter({
      ...filter,
      ...data,
    })
  }, 500)

  const renderRow = (data, idx) => {
    const row = [
      {
        data: (
          <Box>
            <Link
              to={{
                pathname: `${data.id}`,
              }}
              style={{
                whiteSpace: 'nowrap',
                textDecoration: 'none',
              }}
            >
              <img
                src={data?.image?.thumbnail || `/no-image.png`}
                width={64}
                height={48}
                alt="image"
                style={{ objectFit: 'contain' }}
              />
            </Link>
          </Box>
        ),
      },
      {
        data: (
          <Box>
            <Link
              to={{
                pathname: `${data.id}`,
              }}
              style={{
                whiteSpace: 'nowrap',
                textDecoration: 'none',
              }}
            >
              <Typography color="Highlight" style={{ maxWidth: '200px', whiteSpace: 'pre-wrap' }}>
                {data.value}
              </Typography>
            </Link>
          </Box>
        ),
      },
      data.label,
      data.created_at,
      data.updated_at,
      {
        data: (
          <Typography>{data.status === true ? 'Hoạt đông' : 'Ngừng họat động'}</Typography>
        ),
      },

      {
        data: (
          <Stack direction="row" justifyContent="flex-end" alignItems="center">
            <Tooltip title="Chỉnh sửa">
              <IconButton
                color="warning"
                size="small"
                disabled={!isUpdate(actions)}
                onClick={() => {
                  setOpenDrawer(true)
                  setOpenDrawerMode('edit')
                  setOpenDrawerData(data)
                }}
              >
                <DriveFileRenameOutlineOutlinedIcon fontSize="small" />
              </IconButton>
            </Tooltip>
            {
              <Tooltip title="Xóa">
                <IconButton
                  color="error"
                  size="small"
                  disabled={!isDelete(actions) || Number(data?.order || 0) < 0}
                  onClick={() => handleDeleteTag(data.id)}
                >
                  <DeleteOutlinedIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            }
          </Stack>
        ),
        align: 'right',
      },
    ]

    return row
  }

  React.useEffect(() => {
    fetchData()
  }, [filter])

  return (
    <MainContent>
      <Box sx={{ width: '100%' }}>
        <Grid container>
          <Grid item md={6} xs={6}>
            <Typography variant="h4">Danh sách thẻ</Typography>
          </Grid>
          <Grid item md={6} xs={6} textAlign={'right'}>
            <Button
              variant="contained"
              startIcon={<PlusOutlined />}
              disabled={!isCreate(actions)}
              onClick={() => {
                setOpenDrawerMode('add')
                setOpenDrawer(true)
              }}
            >
              Thêm mới
            </Button>
          </Grid>
        </Grid>

        <Box className="listing-toolbar">
          <ListingTableToolbar
            loading={isLoading}
            data={filter}
            onChange={(val) => handleChangeFilter(val)}
            onsubmit={(val) => {
              handleSearch(val)
            }}
          />
        </Box>

        <Box className="listing-body">
          <Paper sx={{ width: '100%', mb: 2, overflowX: 'auto' }}>
            <UiTable
              loading={isLoading}
              columns={[
                {
                  align: 'left',
                  disablePadding: false,
                  name: 'Ảnh',
                },
                {
                  align: 'left',
                  disablePadding: false,
                  name: 'Tên',
                },
                {
                  align: 'left',
                  disablePadding: false,
                  name: 'Mô tả',
                },
                {
                  align: 'left',
                  disablePadding: false,
                  name: 'Thời gian tạo',
                },
                {
                  align: 'left',
                  disablePadding: false,
                  name: 'Thời gian cập nhật',
                },
                {
                  align: 'left',
                  disablePadding: false,
                  name: 'Trạng thái',
                },
                {
                  align: 'right',
                  disablePadding: false,
                  name: 'Hoạt động',
                },
              ]}
              data={(data || []).map((item, idx) => renderRow(item, idx))}
              onCheckAll={() => { }}
              hasCheckAll={false}
            />

            <TablePagination
              sx={{ maxWidth: '100%' }}
              labelRowsPerPage="Số hàng"
              rowsPerPageOptions={[10, 50, 100]}
              component="div"
              count={pagination?.total || 0}
              rowsPerPage={pagination?.per_page || 2}
              page={pagination?.current_page - 1 || 0}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </Box>

        <MainDrawer
          title={openDrawerMode === 'add' ? 'Tạo thẻ mới' : 'Chỉnh sửa thẻ'}
          open={openDrawer}
          onClose={() => {
            setOpenDrawer(false)
            setOpenDrawerData({})
          }}
        >
          <TagForm
            mode={openDrawerMode}
            value={openDrawerData}
            actions={actions}
            onCreated={() => {
              setOpenDrawer(false)
              setOpenDrawerData({})
              fetchData()
            }}
            setOpenDrawer={setOpenDrawer}
            onEdited={async (id, value) => {

              setData(
                data.map((item) => {
                  if (item.id === id) {
                    return {
                      ...item,
                      ...value,
                    }
                  }

                  return item
                })
              )

              setOpenDrawer(false)
              setOpenDrawerData({})
              await fetchData()
            }}
          />
        </MainDrawer>
      </Box>
    </MainContent>
  )
}
