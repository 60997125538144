// project import
import SimpleBar from 'components/third-party/SimpleBar'

import { Box, List } from '@mui/material'
import NavItem from './Navigation/NavItem'
import NavGroup from './Navigation/NavGroup'
import { useSelector } from 'react-redux'
// ==============================|| DRAWER CONTENT ||============================== //

import { MenuItem } from 'models/common'
import { RootState } from 'redux/store'
import menuItems from 'menu-items'
import './style.scss'
import { isRead } from 'utils/permissions/action-permission'
import { isObject } from 'lodash'

const DrawerContent = () => {
  const userInfo = useSelector((state: RootState) => state.user.user)
  const permissions = userInfo?.permission
  const isAdmin = userInfo?.is_admin

  const renderNavItem = (item: MenuItem) => {

    if (item.children && Array.isArray(item.children)) {
      const filteredChildren = isAdmin
        ? item.children
        : item.children.filter((child) => isRead(isObject(permissions) ? permissions[child?.code || ''] : []))

      if (filteredChildren.length > 0) {
        const navGroup = isAdmin ? item : { ...item, children: filteredChildren }
        return <NavGroup key={`${item.id}`} item={navGroup} />
      }
    } else if (isAdmin || isRead(isObject(permissions) ? permissions[item?.code || ''] : [])) {
      return <NavItem item={item} key={item.id} />
    }

    return null
  }

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      <Box sx={{ flexGrow: 1, pt: 2 }}>
        <List className="menu-list">{(menuItems.items || []).map((item) => renderNavItem(item))}</List>
      </Box>
    </Box>
  )

  return (
    <SimpleBar
      sx={{
        '& .simplebar-content': {
          display: 'flex',
          flexDirection: 'column',
        },
      }}
    >
      {content}
      {/* <Navigation /> */}
    </SimpleBar>
  )
}

export default DrawerContent
