import { FormHelperText, styled as MUIStyled, TextField, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import UiFieldLable from './ui/UiFieldLable'
import UploadSingleImage from './UploadSingleImage'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
export interface IField {
  name?: string | null
  size?: string
  defaultValue?: string | null
  value?: string | object | null
  error?: string
  touched?: boolean
  label?: string | null
  placeholder?: string
  caption?: string
  multiline?: boolean
  rows?: number
  style?: any
  type?: 'image' | 'text' | 'date' | 'datetime' | 'checkbox' | 'number'
  required?: boolean
  colorCaption?: string
  marginBottom?: number | string
  disabled?: boolean
}

interface Props extends IField {
  className?: string
  onChange(value: string | object): void
}
const Field = MUIStyled(
  ({
    className,
    size,
    value,
    defaultValue,
    error,
    touched = true,
    type,
    label,
    placeholder,
    caption,
    onChange,
    colorCaption,
    required,
    multiline,
    rows,
    marginBottom,
    disabled = false,
    ...other
  }: Props): JSX.Element => {
    const renderTextField = (number = false) => {
      if (!!defaultValue) {
        return (
          <TextField
            disabled={disabled}
            type={number ? 'number' : 'text'}
            size="small"
            defaultValue={defaultValue}
            multiline={multiline}
            rows={rows}
            className="input"
            placeholder={placeholder}
            onChange={(e) => onChange(e.target.value)}
            error={touched && Boolean(error)}
          />
        )
      }

      return (
        <TextField
          disabled={disabled}
          type={number ? 'number' : 'text'}
          size="small"
          value={value}
          defaultValue={defaultValue}
          multiline={multiline}
          rows={rows}
          className="input"
          placeholder={placeholder}
          onChange={(e) => onChange(e.target.value)}
          error={touched && Boolean(error)}
        />
      )
    }

    return (
      <Box className={[className].join(' ')} {...other} style={{ marginBottom: marginBottom ? marginBottom : 'auto' }}>
        <Box className="label_div">
          <UiFieldLable lable={label!} required={required} />
          {caption ? (
            <Typography variant="h5" color={colorCaption}>
              {caption}
            </Typography>
          ) : (
            <Box />
          )}
        </Box>
        {type === 'image' ? (
          <UploadSingleImage
            disabled={disabled}
            multiple={false}
            className="upload_image input"
            image={value as object}
            onChange={(image: object) => {
              onChange(image)
            }}
          />
        ) : type === 'date' ? (
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              value={value}
              className="input"
              onChange={(value: any) => {
                onChange(value)
              }}
              renderInput={(params) => <TextField fullWidth size="small" {...params} />}
            />
          </LocalizationProvider>
        ) : type === 'datetime' ? (
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DateTimePicker
              value={value}
              onChange={(val: any) => onChange(val)}
              renderInput={(params: any) => {
                return (
                  <TextField
                    fullWidth
                    size="small"
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      placeholder: placeholder,
                    }}
                  />
                )
              }}
            />
          </LocalizationProvider>
        ) : type === 'number' ? (
          renderTextField(true)
        ) : (
          renderTextField()
        )}
        {touched && error ? <FormHelperText error>{error}</FormHelperText> : null}
      </Box>
    )
  }
)(({ theme, caption }) => {
  return {
    display: 'flex',
    flexDirection: 'column',
    '& .label_div': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      // marginTop: '4px',
    },
    '& .input': {
      marginTop: theme.spacing(1),
    },
    '& .upload_image': {
      alignSelf: 'center',
      width: '100%',
    },
  }
})

export default Field
