import { lazy } from 'react'

// project import
import Loadable from 'components/Loadable'
import MainLayout from 'layout/MainLayout'
import Home from 'pages/Home'

const BannerListPage = Loadable(lazy(() => import('pages/BannerManage/List')))
const BannerManageEditorPage = Loadable(lazy(() => import('pages/BannerManage/Editor')))
const BannerRoutes = [
  {
    path: '/home',
    element: <Home />,
  },
  {
    path: 'banner_manage/banners',
    element: <BannerListPage />,
  },
  {
    path: 'banner_manage/banner_editor/:id',
    element: <BannerManageEditorPage />,
  },
  {
    path: 'banner_manage/banner_editor',
    element: <BannerManageEditorPage />,
  },
]

export default BannerRoutes
