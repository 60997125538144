import { lazy } from 'react'

// project import
import Loadable from 'components/Loadable'
import { MODULE } from 'constants/list-module'

const CustomerListing = Loadable(lazy(() => import('pages/Customer/Listing/index')))
const CustomerDetail = Loadable(lazy(() => import('pages/Customer/Detail/index')))

const CustomerRoutes = [
  {
    path: 'customers',
    element: <CustomerListing />,
    code: MODULE.CUSTOMER,
  },
  {
    path: 'customers/:id',
    element: <CustomerDetail />,
    code: MODULE.CUSTOMER,
  },
]

export default CustomerRoutes
