// assets

// icons
import WebIcon from '@mui/icons-material/Web'
import { MODULE } from 'constants/list-module'

const PopUp = {
  id: 'pop-up',
  title: 'Pop Up',
  icon: <WebIcon />,
  url: '/popups',
  role: ['*'],
  code: MODULE.POPUP,
  type: 'single',
}

export default PopUp
