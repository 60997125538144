import { lazy } from 'react'

// project import
import Loadable from 'components/Loadable'
import { MODULE } from 'constants/list-module'

const MediasManagementPage = Loadable(lazy(() => import('pages/MediasManagementPage/index')))

const MediasManagementRoutes = [
  {
    path: 'medias-management',
    element: <MediasManagementPage />,
    code: MODULE.MEDIAS_MANAGEMENT,
  },
]

export default MediasManagementRoutes
