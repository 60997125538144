import WebIcon from '@mui/icons-material/Web'
import { MODULE } from 'constants/list-module'

const LandingPage = {
  id: 'lading-page',
  title: 'Landing Page',
  icon: <WebIcon />,
  url: '/landing_pages',
  code: MODULE.LANDINGPAGE,
  role: ['*'],
  type: 'single',
}

export default LandingPage
